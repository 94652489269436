import { Card, Grid, Input, InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import Searchbar from '../forms/Searchbar';
//import Iconify from '../iconify';

const TableActionBar = ({
    onRequestSearch
}) => {

    //const classes = useStyles()

    return ( 
        <Grid container>
            <Grid item xs>
                <Searchbar onRequestSearch={onRequestSearch} />
            </Grid>
        </Grid>
     );
}

/*const useStyles = makeStyles(theme => ({
    card_container:{
        padding:'8px 16px'
    }
}))*/
 
export default TableActionBar;