//import logo from './logo.svg';
//import './App.css';
import Router from './routes'
import ThemeProvider from './theme';
import { MotionLazyContainer } from './components/animate';
import SnackbarProvider from './components/snackbar';
import { UpdateStatusProvider } from './providers/UpdateStatusProvider/UpdateStatusProvider';

function App() {
  return (
    <> 
      <MotionLazyContainer>
        <ThemeProvider>
          <SnackbarProvider>
            <UpdateStatusProvider>
              <Router />
            </UpdateStatusProvider>
            
          </SnackbarProvider>
        </ThemeProvider>
      </MotionLazyContainer>
    </>
  );
}

export default App;
