import { CONFIG_SETTINGS } from '../../config';
import { generic_server, private_server } from '../../utils/axios';
import { USER_TYPES } from '../../utils/enums';
import { fileTypeByUrl } from '../../utils/files';
import { onGetUserTypeFromToken } from '../../utils/general';


const useClientLocation = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const userType = onGetUserTypeFromToken()
            if(userType === USER_TYPES.ADMIN){
                const request = await private_server.get('/clientlocation/all', {params:params})
                result.data = request.data.data?.client_locations??[]
                result.count = request.data.data.count
            }else{
                const request = await private_server.get('/clientlocation/all/nutritionist', {params:params})
                result.data = request.data.data?.client_locations??[]
                result.count = request.data.data.count
            }
            
            return result
        },
        onGetAllNutritionist: async(userid, params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get(`/clientlocation/all/${userid}/nutritionist`, {params:params})
            result.data = request.data.data?.client_locations??[]
            result.count = request.data.data.count
            return result
        },
        
        onGet: async(idclientlocation) => {
            let result = null
            const request = await private_server.get(`/clientlocation/${idclientlocation}`)
            result = request.data.data.client_location
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/clientlocation', data2send)
            result = request.data.data?.client_location?.id_client_location
            return result
        },
        onEdit: async(idclientlocation, data2send) => {
            await private_server.patch(`/clientlocation/${idclientlocation}`, data2send)
        }, 
        onDelete: async(id) => {
            await private_server.delete(`/clientlocation/${id}`)
        },

    }

    return {
        actions
    }
}
 
export default useClientLocation;