import { private_server } from "../utils/axios"
import { USER_TYPES } from "../utils/enums"
import { onGetUserTypeFromToken } from "../utils/general"

export const getPatients = async(idclient, params) => {

    let result = {
        data:[], 
        count:0
    }
    const userType = onGetUserTypeFromToken()
    let ANDArgs = [{field:'user_type_id', operator:"=", value:USER_TYPES.USER}]
    if(idclient)ANDArgs.push({field:'client_id', operator:"=", value:idclient})
    
    let _params = {...params}
    if(_params.filter){
        let aux = _params.filter
        aux = JSON.parse(aux)
        if(aux["AND"])  aux["AND"] = [...aux["AND"], ...ANDArgs]
        _params.filter = JSON.stringify(aux)
    }else{
        _params.filter = JSON.stringify({AND:ANDArgs})
    }

    console.log("Params to add..", _params)

    if(userType === USER_TYPES.ADMIN){
        const request = await private_server.get('/user/all', {params:_params})
        result.data = request.data.data?.users??[]
        result.count = request.data.data.count
    }else{
        const request = await private_server.get('/user/all/nutritionist', {params:_params})
        result.data = request.data.data?.users??[]
        result.count = request.data.data.count
    }
     
    return result
}

export const getUsers = async(params) => {

    let result = {
        data:[], 
        count:0
    }
    const userType = onGetUserTypeFromToken()
    if(userType === USER_TYPES.ADMIN){
        const request = await private_server.get('/user/all', {params:params})
        result.data = request.data.data?.users??[]
        result.count = request.data.data.count
    }else{
        const request = await private_server.get('/user/all/nutritionist', {params:params})
        result.data = request.data.data?.users??[]
        result.count = request.data.data.count
    }
     
    return result
}

export const getNutritionists = async(params) => {

    let result = {
        data:[], 
        count:0
    }
    const userType = onGetUserTypeFromToken()
    let ANDArgs = [{field:'user_type_id', operator:"=", value:USER_TYPES.NUTRITIONIST}]
    
    let _params = {...params}
    if(_params.filter){
        let aux = _params.filter
        aux = JSON.parse(aux)
        if(aux["AND"])  aux["AND"] = [...aux["AND"], ...ANDArgs]
        _params.filter = JSON.stringify(aux)
    }else{
        _params.filter = JSON.stringify({AND:ANDArgs})
    }

    if(userType === USER_TYPES.ADMIN){
        const request = await private_server.get('/user/all', {params:_params})
        result.data = request.data.data?.users??[]
        result.count = request.data.data.count
    }else{
        const request = await private_server.get('/user/all/nutritionist', {params:_params})
        result.data = request.data.data?.users??[]
        result.count = request.data.data.count
    }
     
    return result
}
