import { private_server } from "../utils/axios"

export const getClientProgramsCatalog = async(params) => {

    let result = {
        data:[], 
        count:0
    }
    const request = await private_server.get('/clientprogram/programs', {params:params})
    console.log(request.data.data)
     
    result.data = request.data.data?.programs??[]
    result.count = request.data.data.count
    return result
}

export const getClientPrograms = async(params) => {
    let result = {
        data:[], 
        count:0
    }
    const request = await private_server.get('/clientprogram/all', {params:params})
    console.log(request.data.data)
     
    result.data = request.data.data?.client_programs??[]
    result.count = request.data.data.count
    return result
}

export const getClientProgram = async(idclientprogram) => {
    let result = null
    const request = await private_server.get(`/clientprogram/${idclientprogram}`)
    result = request.data.data.client_program
    return result
}

export const addProgram2Client = async(data2send) => {
    await private_server.post('/clientprogram/', data2send)
}

////////////////////////// USER CLIENT PROGRAMS  ///////////////////////////////

export const getAllClientProgramUsers = async(params) => {
    let result = {
        data:[], 
        count:0
    }
    const request = await private_server.get('/userclientprogram/all', {params:params})
    console.log(request.data.data)
     
    result.data = request.data.data?.user_client_programs??[]
    result.count = request.data.data.count
    return result
}

export const addUser2ClientProgram = async(data2send) => {
    await private_server.post('/userclientprogram/', data2send)
}

