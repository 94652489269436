import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import BaseModal from '../../../components/modals/BaseModal';
import useCart from '../../../hooks/api/useCart';
import Searchbar from '../../../components/forms/Searchbar';
import useProduct from '../../../hooks/api/useProduct';
import LoadingContainer from '../../../components/loaders/LoadingContainer';
import qs from 'query-string'
import NotFoundMessagePlaceholder from '../../../components/platform/placeholders/NotFoundMessagePlaceholder';
import GenericProduct from '../../../components/platform/products/GenericProduct';
import { Grid, Icon, Typography, useTheme } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { isProductAlreadyInCart } from '../../../utils/platform';
import { useSnackbar } from 'notistack';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import useResponsive from '../../../hooks/useResponsive';
import PatientDoctorAppointment from '../../../assets/illustrations/PatientDoctorAppointment';
import { Box } from '@mui/system';
import Iconify from '../../../components/iconify';

const AddProduct2CartModal = (props) => {

    const {open, onClose, onRefresh, cart} = props
    const  classes = useStyles()
    const isMobile = useResponsive('down', 'sm')
    const [searching, setSearching] = useState(false)
    const [products, setProducts] = useState([])
    const [selected, setSelected] = useState()
    const [firstsearch, setFirstSearch] = useState(false)
    const [error, setError] = useState()
    const cartManager = useCart()
    const productManager = useProduct()
    const theme = useTheme()


    useEffect(() => {
        if(!open){
            setProducts([])
            setError(null)
            setSearching(false)
            setSelected(null)
            setFirstSearch(false)
        }
    },[open])

    const onRequestSearch = useCallback(async(_value) => {
        console.log('Buscar', _value)
        try {
            if(_value && _value.length > 3){
                setSearching(true)
                const filter=JSON.stringify({AND:[{field:'enabled', operator:'=', value:1}]})
                let _filter = encodeURIComponent(filter)
                const _params = {limit:100, offset:0, search:_value}
                const query = qs.stringify(_params, {sort:false})

                const params = `?${query}&filter=${_filter}`
                const response = await productManager.actions.onSearch(params)
                console.log(response)
                setProducts(response.data)
                setSearching(false)
                setFirstSearch(true)
            }else{
                if(_value === '') setFirstSearch(false)
            }
        } catch (error) {
            console.log(error)
            
            setSearching(false)
        }
    },[]) 

    const onChangeQuantity = async(_selected_product, _quantity, line_item) => {
        const data2send = {
            product_id: _selected_product?.id_product || _selected_product?.product_id,
            quantity:_quantity
        }
        try {
            setSelected(_selected_product)
            await cartManager.actions.onAddRemoveLineItem(cart,line_item,data2send)
            await onRefresh() 
            
        } catch (error) {
            console.log(error)
            setError(error)
        }
        setSelected(null)
    }

    const onSelectedProduct = async(_selected_product) => {
        console.log(_selected_product)
        const data2send = {
            product_id: _selected_product?.id_product || _selected_product?.product_id,
            quantity:1
        }
        try {
            setSelected(_selected_product)
            await cartManager.actions.onAddRemoveLineItem(cart,null,data2send)
            await onRefresh() 
            onClose()
        } catch (error) {
            console.log(error)
            setError(error)
        }
    }

    const line_items = cart?.line_items ?? []

    let emptyContent = null

    if(firstsearch){
        emptyContent = <NotFoundMessagePlaceholder />
    }else{
        emptyContent = (
            <Grid container direction='column' alignItems='center'>
                <Grid item  >
                    <Box sx={{maxWidth:400, width:'100%'}}>
                        <PatientDoctorAppointment/>
                    </Box>
                </Grid>
                <Grid item>
                    <Typography align='center'>Estas a unos segundos de cambiar positivamente la vida de tu paciente</Typography>
                    <Typography align='center'>busca los productos que necesita. </Typography>
                </Grid>
                <Grid item>
                    <Box><Iconify icon='fa-regular:smile-wink' sx={{width:32, height:32, color:theme.palette.primary.main, marginTop:4}}/></Box>
                </Grid>
            </Grid>
        )
    }

    return ( 
        <BaseModal maxWidth='md' fullScreen={isMobile}
        title='Selecciona tus medicamentos'
        open={open} 
        onClose={onClose} >
            <div>
                <Searchbar onRequestSearch={onRequestSearch} disabled={searching} loading={searching}/>
                <div>
                    {Boolean(products.length === 0) && <div style={{minHeight:600, paddingTop:60}}>{emptyContent}</div>}
                    {(products.length > 0) && (
                        <div style={{paddingBottom:16}}>
                            <Scrollbars autoHeight autoHeightMin={600}>
                                <div className={classes.product_container}>
                                
                                    <Grid container spacing={2}>
                                        {products.map(item => {

                                            const line_item = isProductAlreadyInCart(item,  line_items, 'id_product')
                                            const isbusy = Boolean(selected && (selected.id_product === item.id_product))

                                            return(
                                                <Grid item xs={12} md={6} key={`product-id-${item?.id_product}`}>
                                                    <GenericProduct 
                                                        disabledPrice disabledOffer
                                                        product={item}
                                                        loading={isbusy}
                                                        quantity={line_item ? line_item.quantity : 0}
                                                        onSelectedProduct={onSelectedProduct}
                                                        onChangeQuantity={(p,q) => onChangeQuantity(p,q,line_item)}
                                                    />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </div>
                            </Scrollbars>
                        </div>
                        
                        
                    )}
                </div>
                {Boolean(error) && <ErrorAlert
                                error={error} 
                                onClose={() => setError(null)} />}
                
            </div>
        </BaseModal>
     );
}

const useStyles = makeStyles(theme => ({
    description:{
        marginBottom:16
    },
    product_container:{
        padding:'32px 0px',
        maxHeight:300,
        paddingRight:8
    }
}))
 
export default AddProduct2CartModal;

