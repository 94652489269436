import { Stack } from '@mui/system';
import React from 'react';
import CeluIcon from '../CeluIcon';
import cartIcon from '../../../assets/icons/ecommerce/shopping-cart.svg'
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const EmptyCartPlaceholder = () => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <Stack alignItems='center' spacing={2}>
                <CeluIcon icon={cartIcon} />
                <Typography variant='body2' className={classes.text} align='center'>
                    {'Aún no hay productos\n en tu carrito'}
                </Typography>
            </Stack>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'40px 16px'
    },
    text:{
        maxWidth:200
    }
}))
 
export default EmptyCartPlaceholder;