import { useCallback, useEffect, useState } from "react";
import useInitState from "../../../../hooks/useInitState";
import { getAllClientProgramUsers } from "../../../../services/clientProgramService";
import useTableSettings from "../../../../hooks/useTableSettings";
import { useBoolean } from "../../../../hooks/useBoolean";

const usePatientBusinessProgramSection = ({
    idclient,
    idclientprogram,
}) => {

    const {
        loading
    } = useInitState()

    const add_modal = useBoolean(false)

    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState("")
    const tableController = useTableSettings({
        initial_params:{
            order_by:JSON.stringify(['user_first_name']),
            order:0,
            limit:100, 
            offset:0,
        }
    })

    const onRequestSearch = useCallback((value) => {
        setSearch(value)
        tableController.onUpdatePaginationValue('offset', 0)
    }, [tableController])


    const onGetParams = useCallback(() => {
        let result = {...tableController.pagination}
        result.offset = tableController.pagination.offset * tableController.pagination.limit
        let ANDargs = [{field:'client_program_id', operator:"=", value:idclientprogram}]
        if(search){
            let OR_array = [
                {field:'user_first_name', operator:"LIKE", value:`%${search}%`},
                {field:'user_fathers_last_name', operator:"LIKE", value:`%${search}%`},
                {field:'user_mothers_last_name', operator:"LIKE", value:`%${search}%`},
                {field:'user_email', operator:"LIKE", value:`%${search}%`},
            ]
            ANDargs.push({OR:OR_array})
        }
        result.filter = JSON.stringify({AND:[...ANDargs]})
        return result
    }, [tableController, idclientprogram, search])

    const onGetPatients = useCallback(async() => {
        const params = onGetParams()
        const _request = await getAllClientProgramUsers(params)
        setData(_request.data)
        setTotal(_request.count)

    }, [onGetParams])

    const initialize = useCallback(async() => {
        loading.onTrue()
        try {
            await onGetPatients()
        } catch (e) {
            console.log(e)
        }

        loading.onFalse()
    }, [loading, onGetPatients])

    useEffect(() => {
        window.scrollTo({
            top:0, behavior:'smooth'
        })
        initialize()
    }, [tableController.pagination])
    

    return {
        loading,
        add_modal,
        data, 
        total,
        tableController,
        onRequestSearch,
        onGetPatients
    };
}
 
export default usePatientBusinessProgramSection;