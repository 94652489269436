import { useMemo, useState, useCallback } from 'react';

// ----------------------------------------------------------------------

/**
 * @typedef {Object} useErrorReturn
 * @property {string} value - The current error string value.
 * @property {Function} onClear - Sets the value of error to empty string.
 * @property {Function} setValue - Sets the value explicitly.
 */

/**
 * A custom hook to manage string state with utility functions.
 *
 * @param {string} [defaultValue=null] - Initial string value.
 * @returns {useErrorReturn} - An object containing the boolean state and utility functions.
 */
export function useError(defaultValue = "") {
  const [value, setValue] = useState(defaultValue);

  const onClear = useCallback(() => {
    setValue("");
  }, [setValue]);

  const onSet = useCallback((data) => {
    console.log("actualizar error", data)
    setValue(data);
  }, [setValue]);

  const memoizedValue = useMemo(
    () => ({
      value,
      onClear,
      onSet,
    }),
    [value, onClear, onSet]
  );

  return memoizedValue
}
