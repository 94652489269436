import { Container, Fade } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from '../components/custom-breadcrumbs/CustomBreadcrumbs';
import LoadingContainer from '../components/loaders/LoadingContainer';

const PageLayout = ({
    title, title_seo, links, children, stretch, loading, action, enable_return
}) => {

    let content = loading ? <LoadingContainer /> 
    : <Fade in>
        {children}
    </Fade>



    return ( 
        <div>
            <Helmet>
                {title_seo ? <title> {title_seo} </title> : null}
            </Helmet>
            <Container maxWidth={stretch ? 'lg' : false}>
                <CustomBreadcrumbs
                    enable_return={enable_return}
                    heading={title}
                    links={links??[]}
                    action={action}
                />
                <>
                    {content}
                </>
            </Container>
        </div>
     );
}
 
export default PageLayout;