import { Box, Card, Checkbox, Grid, LinearProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import ContainedButton from '../../../components/actions/ContainedButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import useProduct from '../../../hooks/api/useProduct';
import Searchbar from '../../../components/forms/Searchbar';
import { onGetFullname, removeElementAt, sleep } from '../../../utils/general';
import Scrollbars from 'react-custom-scrollbars';
import useClientProduct from '../../../hooks/api/useClientProduct';
import BaseModal from '../../../components/modals/BaseModal';
import useInitState from '../../../hooks/useInitState';
import { getPatients } from '../../../services/userService';
import { addUser2ClientProgram, getAllClientProgramUsers } from '../../../services/clientProgramService';
import { grey } from '@mui/material/colors';
import LoadingContainer from '../../../components/loaders/LoadingContainer';
import EmptyContent from '../../../components/empty-content';

const AddUser2ClientProgramModal = ({
    open,
    onClose,
    onRefresh,
    idclient,
    idclientprogram,
    ...others
}) => {

    const { client_products=[], general_products=[], client_id} = others
    const [sending, setSending] = useState(false)
    const navigate = useNavigate()
    const [search, setSearch] = useState("")
    const productManager = useProduct()
    const clientProductManger = useClientProduct()
    const [progress, setProgress] = useState(0)

    const {
        loading,
        error,
        submitting
    } = useInitState()

    const [current_users, setCurrentUsers] = useState([])
    const [selected_users, setSelectedUsers] = useState([])

    const initalize = useCallback(async() => {
        loading.onTrue()
        try {
            setSearch("")
            setProgress(0)
            setSelectedUsers([])
            error.onSet("")
            const _clientusers = await getPatients(0, {
                limit:10000, 
                offset:0,
                filter:JSON.stringify({AND:[
                    {field:"nutritionist_id", operator:">", value:`0`},
                    {field:"client_id", operator:"=", value:idclient}
                ]})
            })
            
            const _userprograms = await getAllClientProgramUsers({
                filter:JSON.stringify({AND:[
                    {field:'client_program_id', operator:"=", value:idclientprogram}
                ]})
            })
            
            let finalusers = _clientusers.data.filter(el => !_userprograms.data.find(us => us.user_id === el.id_user))
            setCurrentUsers(finalusers)
            
        } catch (error) {
            console.log(error)
        }
        loading.onFalse()
    }, [loading, idclient, idclientprogram])

    useEffect(() => {
        if(open){
            initalize()
        }
        //if(open && general_products && client_products) onInitModule()
        if(!open){
            //setProducts([])
            //setSelectedUsers([])
        }
    },[open])

    const onCheckAll = useCallback(() => {
        if(current_users.length !== selected_users.length){
            let _data2add = [...current_users]
            setSelectedUsers(_data2add)
        }else{
            setSelectedUsers([])
        }
    }, [general_products, selected_users])

    const onSelected = useCallback((product, status) => {
        let _products = [...selected_users]
        if(status){
            _products.push(product)
        }else{
            const idx = selected_users.findIndex(el => el.id_user === product?.id_user)
            _products = removeElementAt(_products, idx)
        }
        setSelectedUsers(_products)

    }, [selected_users])

    const onSubmit = async() => {

        submitting.onTrue()

        try {
            //await onRefresh()

            for(let i=0; i<selected_users.length; i++){
                const _user = selected_users[i]
                let data2add = {
                    user_id: _user?.id_user,
                    client_program_id:idclientprogram
                }
                console.log(data2add)
                try {
                    await addUser2ClientProgram(data2add)
                    setProgress(((i+1)/selected_users.length)*100)
                } catch (error) {
                    
                }
            }
            await onRefresh()
        } catch (e) {
            error.onSet(e?.message)
            submitting.onFalse()
            return
        }

        submitting.onFalse()
        onClose()

    }

    let _currentusers = [...current_users]
    if(search !== "") _currentusers = _currentusers.filter(el => 
        (el?.first_name??"").toLowerCase().includes(search?.toLocaleLowerCase()) ||
        (el?.fathers_last_name??"").toLowerCase().includes(search?.toLocaleLowerCase()) ||
        (el?.mothers_last_name??"").toLowerCase().includes(search?.toLocaleLowerCase())
    )  

    return ( 
        <BaseModal maxWidth='md'
        title='Agregar usuarios al programa'
        open={open} 
        onClose={null} 
        onCloseBtn={() => !sending ? onClose() : error.onSet("No puedes cerrar la ventana, mientras se están agregando los usuarios")}
        >
            <LoadingContainer loading={loading.value}>
                <div>
                    <Typography variant='body2' >
                        Seleccione los usuarios que desee agregar
                    </Typography>
                    <Searchbar
                        onRequestSearch={(data) => setSearch(data)}
                        waitTime={300}
                    />
                    
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{minHeight:500, py:3}}>
                                        <Stack direction='row' alignItems='center'>
                                            <Checkbox checked={selected_users.length === current_users.length} onChange={onCheckAll} />
                                            <Typography variant='subtitle2'>Todos los usuarios</Typography>
                                        </Stack>
                                        
                                        <Scrollbars autoHeight={500} autoHeightMax={500} >
                                            {_currentusers.map((item, key) => {
                                                if(key > 500) return null

                                                const selected = !!selected_users.find(el => el.id_user === item?.id_user)

                                                return(
                                                    <UserRow 
                                                        selected={selected}
                                                        onSelected={onSelected}
                                                        key={`product-list-item-${item?.id_user}`} 
                                                        user={item}
                                                    />
                                                )
                                            })}
                                            {_currentusers.length === 0 && (
                                                <EmptyContent description={"No hay usuarios para dar de alta en este programa"}  />
                                            )}
                                        </Scrollbars>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{minHeight:500, py:3}}>
                                        <Typography variant='subtitle2'>Usuarios seleccionados ({selected_users.length})</Typography>
                                        <Scrollbars autoHeight={500} autoHeightMax={500} >
                                            {selected_users.map((item, key) => {
                                                if(key > 200) return null

                                                return(
                                                    <UserRow 
                                                        selected={true}
                                                        onSelected={onSelected}
                                                        key={`product2-list-item-${item?.id_user}`} 
                                                        user={item}
                                                    />
                                                )
                                            })}
                                        </Scrollbars>
                                </Box>
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {error.value && 
                                        <Grid item xs={12}>
                                            <ErrorAlert 
                                            error={error.value} 
                                            onClose={() => error.onSet(null)} />
                                        </Grid> 
                                    }
                                    <Grid item xs={12}>
                                        <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                                            {submitting.value && <Typography variant='h6'>
                                                Progreso...{Math.round(progress)}%
                                            </Typography>}
                                            <ContainedButton  loading={submitting.value} onClick={onSubmit}>
                                                Agregar {selected_users.length} usuarios
                                            </ContainedButton>
                                        </Stack>
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </LoadingContainer>
        </BaseModal>
     );
}

 
export default AddUser2ClientProgramModal;

const UserRow = ({
    selected,
    user,
    onSelected
}) => {
    const _user = user
    return(
        <Box  sx={{
            borderBottom:`1px solid ${grey[300]}`,
            py:0.5
        }}>
            <Stack direction='row' alignItems='center'>
                <Checkbox checked={selected} onChange={() => onSelected(_user, !selected)} />
                <div>
                    <Typography sx={{p:0, pl:0.5}} variant='body2'>
                        {onGetFullname(_user)}
                    </Typography>
                    <Typography sx={{p:0, pl:0.5}} variant='caption'>
                        {_user?.email}
                    </Typography>
                </div>
                
            </Stack>
        </Box>
    )
}
