import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import React, { useState, useEffect, useCallback } from 'react';
import ContainedButton from '../../../components/actions/ContainedButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import InputForm from '../../../components/forms/InputForm';
import BaseModal from '../../../components/modals/BaseModal';
import useCart from '../../../hooks/api/useCart';
import useForm from '../../../hooks/useForm';

const EditCartModal = (props) => {

    const {open, onClose, onRefresh, origin} = props
    const  classes = useStyles()
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const [user, setUser] = useState()
    const formManager = useForm(form_data)
    const cartManager = useCart()
    const {form, onValidateForm, onGetFormData, onResetForm, onChangeForm, onInitForm} = formManager

    useEffect(() => {
        if(open && origin) onInitModule()
        if(!open) onResetForm()
    },[open, onResetForm])

    const onInitModule = useCallback(async() => {
        const _cartrq = await cartManager.actions.onGetCart(origin?.id_cart)
        setUser(_cartrq)
        onInitForm(_cartrq)
    }, [origin, cartManager, onInitForm])

    const onSubmit = async() => {
        console.log(form)
        const valid = onValidateForm(form)
            
        if(!valid){
            setError({message:'Debe llenar todos los campos solicitados'})
            return
        }

        let data2send = onGetFormData({origin: user, eraseEmptySpaces:true})

        //data2send.zip = '03200'
        //data2send.language_id = LANGUAGES.SPANISH
        //data2send.user_gender_id = GENDERS.UNKNOWN
        //data2send.timezone = moment.tz.guess()
        //data2send.indigenous = 0

       // if(data2send.password !== data2send.passwordConfirmation){
       //     setError({message:'Las contraseñas no coinciden'})
       //     return
       // }s

        setSending(true)

        try {
            await cartManager.actions.onEditCart(origin?.id_cart, data2send)
            await onRefresh()
        } catch (error) {
            setError(error)
            setSending(false)
            return
        }

        setSending(false)
        onClose()

    }

    return ( 
        <BaseModal maxWidth='sm'
        title='Modificar carrito'
        open={open} 
        onClose={onClose} >
            <div>
                <Typography variant='body1' className={classes.description}>
                    Modifique los datos que requiera de este carrito
                </Typography>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputForm data={form.alias} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {error && 
                                    <Grid item xs={12}>
                                        <ErrorAlert 
                                        error={error} 
                                        onClose={() => setError(null)} />
                                    </Grid> 
                                }
                                <Grid item xs={12}>
                                    <Stack direction='row' justifyContent='flex-end' >
                                        <ContainedButton  loading={sending} onClick={onSubmit}>
                                            Guardar
                                        </ContainedButton>
                                    </Stack>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </BaseModal>
     );
}

const useStyles = makeStyles(theme => ({
    description:{
        marginBottom:16
    }
}))
 
export default EditCartModal;

const form_data = {
    alias: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'alias',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Identificador',
            placeholder:'Agrega un nombre o identificardor de este carrito',
            helperText:'Debe ser un valor entre 1 a 255 caracteres'
        },
        rules: {type: 'distance', min:1, max:255}
    },
}