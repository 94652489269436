import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react'
import Iconify from '../iconify';
import ComingSoonIllustration from '../../assets/illustrations/ComingSoonIllustration';
import AnnexByObjectiveReport from './anexo/AnnexByObjectiveReport';

const AnexoReport = ({
    idclient, 
    idclientprogram, 
    period,
    onReturn
}) => {
    return ( 
        <Box>
            <Stack direction='row'>
                <IconButton onClick={onReturn}>
                    <Iconify icon="material-symbols:arrow-back-rounded" />
                </IconButton>
                <Typography variant='h5'>
                    Anexo
                </Typography>
                
            </Stack>
            <Box>
                <Stack>
                    <AnnexByObjectiveReport 
                        idclient={idclient}
                        idclientprogram={idclientprogram}
                        period={period}
                    />
                </Stack>
                
            </Box>
        </Box>
     );
}
 
export default AnexoReport;