import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/loaders/LoadingScreen';
// components

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
// ----------------------------------------------------------------------

// GENERIC
export const PublicAppointmentPage = Loadable(lazy(() => import('../pages/Generic/PublicAppointmentPage/PublicAppointmentPage')));

// AUTH
export const LoginPage = Loadable(lazy(() => import('../layouts/Auth/SignInLayout/SignInLayout')));

// DASH
export const AppDashPage = Loadable(lazy(() => import('../pages/AppDash/AppDash')));

export const UsersListPage = Loadable(lazy(() => import('../pages/Users/UserListPage/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/Users/UserAccountPage/UserAccountPage')));
export const NutritionistListPage = Loadable(lazy(() => import('../pages/Nutritionists/NutritionistListPage/NutritionistListPage')));
export const NutritionistAccountPage = Loadable(lazy(() => import('../pages/Nutritionists/NutritionistAccountPage/NutritionistAccountPage')));
export const PatientListPage = Loadable(lazy(() => import('../pages/Patients/PatientListPage/PatientListPage')));
export const PatientAccountPage = Loadable(lazy(() => import('../pages/Patients/PatientAccountPage/PatientAccountPage')));
export const PatientAppointmentPage = Loadable(lazy(() => import('../pages/Patients/PatientAppointmentPage/PatientAppointmentPage')));

export const BusinessListPage = Loadable(lazy(() => import('../pages/Businesses/BusinessListPage/BusinessListPage')));
export const BusinessAccountPage = Loadable(lazy(() => import('../pages/Businesses/BusinessAccountPage/BusinessAccountPage')));
export const OfficesListPage = Loadable(lazy(() => import('../pages/Offices/OfficesListPage/OfficesListPage')));
export const OfficeAccountPage = Loadable(lazy(() => import('../pages/Offices/OfficeAccountPage/OfficeAccountPage')));

export const ProductsListPage = Loadable(lazy(() => import('../pages/Products/ProductsListPage/ProductsListPage')));
export const ProductPage = Loadable(lazy(() => import('../pages/Products/ProductPage/ProductPage')));

export const EquivalentsListPage = Loadable(lazy(() => import('../pages/Equivalents/EquivalentsListPage/EquivalentsListPage')));
export const EquivalentPage = Loadable(lazy(() => import('../pages/Equivalents/EquivalentPage/EquivalentPage')));
export const EquivalentSMAEPage = Loadable(lazy(() => import('../pages/Equivalents/EquivalentSMAEPage/EquivalentSMAEPage')));


export const BlogsListPage = Loadable(lazy(() => import('../pages/Blogs/BlogsListPage/BlogsListPage')));
export const AddBlogPage = Loadable(lazy(() => import('../pages/Blogs/AddBlogPage/AddBlogPage')));
export const EditBlogPage = Loadable(lazy(() => import('../pages/Blogs/EditBlogPage/EditBlogPage')));

export const RecipesListPage = Loadable(lazy(() => import('../pages/Recipes/RecipesListPage/RecipesListPage')));
export const RecipePage = Loadable(lazy(() => import('../pages/Recipes/RecipePage/RecipePage')));
export const DietsListPage = Loadable(lazy(() => import('../pages/Diets/DietsListPage/DietsListPage')));
export const SmaeDietPage = Loadable(lazy(() => import('../pages/Diets/SmaeDietPage/SmaeDietPage')));
export const SimplifiedEquivalentsDietPage = Loadable(lazy(() => import('../pages/Diets/SimplifiedEquivalentsDietPage/SimplifiedEquivalentsDietPage')));
export const MenusDietPage = Loadable(lazy(() => import('../pages/Diets/MenusDietPage/MenusDietPage')));
export const ColumnsDietPage = Loadable(lazy(() => import('../pages/Diets/ColumnsDietPage/ColumnsDietPage')));
export const OptionsDietPage = Loadable(lazy(() => import('../pages/Diets/OptionsDietPage/OptionsDietPage')));

export const AchievementsListPage = Loadable(lazy(() => import('../pages/Achievements/AchievementsListPage/AchievementsListPage')));


