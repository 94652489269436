import { alpha, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const StatusChipBtn = ({
    status, options, enableOptions
}) => {

    const classes = useStyles()

    if(!status) return null
    return ( 
        
        <Button className={classes.root} style={{background:alpha(status?.color,0.25), color:status?.color}}>
            {status?.label}
        </Button>
     );
}
 

const useStyles = makeStyles(theme => ({
    root:{
        fontSize:'0.750rem',
        fontWeight:500,
        borderRadius:32,
        padding:'2px 8px'
    }
}))

export default StatusChipBtn;