import React from 'react'
import { makeStyles } from '@mui/styles';
import SVGIcon from '../images/SVGIcon';
import { useTheme } from '@mui/material';

const container_size = {
    'small': 48,
    'medium':64,
    'large': 96
}

const CeluIcon = (props) => {

    const {size, icon} = props
    const classes = useStyles()
    const theme = useTheme()

    const container_styles = {
        width: container_size[size??'medium'],
        height: container_size[size??'medium']
    }

    const iconSize = container_size[size??'medium']/2


    return ( 
        <div className={classes.icon_container} style={container_styles}>
            <div className={classes.icon}>
                <SVGIcon src={icon} size={iconSize} color={theme.palette.primary.main} />
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    icon_container:{
        width:100,
        height:100,
        borderRadius:'50%',
        border:'1px solid white',
        background:theme.palette.primary.light,
        position:'relative'
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',

    }
}))
 
export default CeluIcon;