import { Box, Card, CircularProgress, Fade } from "@mui/material";
import { createContext, useCallback, useState } from "react";

export const UpdateStatusContext = createContext(null);

export const UpdateStatusProvider = ({children}) => {


    const [busy, setBusy] = useState(false)

    const onUpdateStatus = useCallback((value) => {
        setBusy(value)
    }, [])


    return(
        <UpdateStatusContext.Provider
            value={{
                onUpdateStatus
            }}
        >
            <Fade in={busy}>
                <Card sx={{
                    position:'fixed',
                    bottom:24, right:24,
                    width:48, height:48,
                    borderRadius:'50%',
                    background: theme=>theme.palette.primary.main,
                    color:'white',
                    display:'flex', flexDirection:'column',
                    alignItems:'center', justifyContent:'center',
                    zIndex:100
                }}>
                    <CircularProgress size={24} sx={{color:'white'}}/>
                </Card>
            </Fade>
            {children}
        </UpdateStatusContext.Provider>
    )

}
