import React from 'react';
import {Button} from '@mui/material'

const TextButton = ({children,...other}) => {
    return ( 
        <Button
            variant='text'
            {...other}
        >
            {children}
        </Button>
     );
}
 
export default TextButton;