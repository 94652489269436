import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import BackgroundIllustration from './BackgroundIllustration';

// ----------------------------------------------------------------------

function ComingSoonIllustration({ ...other }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg width="100%" height="100%" viewBox="0 0 865.76 682.89" xmlns="http://www.w3.org/2000/svg">


        <path fill="#fff" opacity="0.7" d="M827.79,317.41c0,120.47-157.54,218.14-351.87,218.14-114.94,0-217-34.17-281.22-87-.75-.6-1.49-1.22-2.23-1.85q-4.54-3.81-8.82-7.76c-.77-.71-1.54-1.42-2.3-2.15q-5.13-4.84-9.86-9.89-2.51-2.67-4.89-5.39c-17-19.37-29.4-40.52-36.29-62.87q-.42-1.37-.81-2.73A138.85,138.85,0,0,1,124,317.41a143.31,143.31,0,0,1,15.37-64c.12-.27.25-.53.38-.79,44.49-88.87,178.15-153.4,336.13-153.4C670.25,99.27,827.79,196.93,827.79,317.41Z" />
        <path fill="#543081" d="M100.6,257.67s19-5.56,28.69,7c0,0-17.57-4.69-24.3,1.46,0,0,0,22.83,14.05,35.13,0,0,22.25.58,31,9.07,0,0-20.83-7.59-26,3l11.47,18s9.32-6.75,15.43-5.79l-10.75,11.33s17.28,15.42,33.06,6c0,0,22.84-15.72,2.57-51.5a14.58,14.58,0,0,0-7,4.5s.43-8.79,9.86-18.65l-12.86-25.46s-10.72,1.68-16.29,11.11c0,0-3.21-12,9.65-21.65,0,0-10.29-24.21-26.79-33.64,0,0-22.07-13.93-27.86-21.64,0,0-6.85,25.28-9.64,29.57,0,0,8.57,1.93,12.64,7.5,0,0-8.57-1.07-12.64.21C95,223.27,88.88,232.28,100.6,257.67Z" />
        <path opacity="0.2" d="M100.6,257.67s19-5.56,28.69,7c0,0-17.57-4.69-24.3,1.46,0,0,0,22.83,14.05,35.13,0,0,22.25.58,31,9.07,0,0-20.83-7.59-26,3l11.47,18s9.32-6.75,15.43-5.79l-10.75,11.33s17.28,15.42,33.06,6c0,0,22.84-15.72,2.57-51.5a14.58,14.58,0,0,0-7,4.5s.43-8.79,9.86-18.65l-12.86-25.46s-10.72,1.68-16.29,11.11c0,0-3.21-12,9.65-21.65,0,0-10.29-24.21-26.79-33.64,0,0-22.07-13.93-27.86-21.64,0,0-6.85,25.28-9.64,29.57,0,0,8.57,1.93,12.64,7.5,0,0-8.57-1.07-12.64.21C95,223.27,88.88,232.28,100.6,257.67Z" />
        <path fill="#2f2e41" d="M183.65,438.92c-.77-.71-1.54-1.42-2.3-2.15a263.3,263.3,0,0,0-1.22-29.7c-1-11.16-2.52-22.29-4.32-33.36q-1.35-8.31-2.92-16.57c-.53-2.76-1.07-5.51-1.64-8.26l-.87-4.11c-.3-1.4-.54-2.76-.81-4.14a272.24,272.24,0,0,0-8.59-32.5,516.62,516.62,0,0,0-21.57-54.67c-1.18-2.59-2.37-5.19-3.58-7.77-9.58-20.31-19.93-40.28-31.23-59.7,11.47,19.32,22,39.2,31.78,59.44q1.73,3.62,3.41,7.24a520.23,520.23,0,0,1,22.34,55.08A273.66,273.66,0,0,1,171,340.32c.29,1.37.55,2.77.85,4.11l.91,4.13q.88,4.13,1.71,8.26,1.67,8.28,3.08,16.59c1.89,11.1,3.49,22.25,4.62,33.46A255.61,255.61,0,0,1,183.65,438.92Z" />
        <path fill="#2f2e41" d="M413.79,487.69l-15.66-11.3-9.88-16.58s6.48-.33,6.81-.33,15,4.5,15.26,5.38S414.52,482.87,413.79,487.69Z" />
        <path fill="#543081" d="M286.51,480.19H331a0,0,0,0,1,0,0v10a0,0,0,0,1,0,0H296.28a9.78,9.78,0,0,1-9.78-9.78v-.22A0,0,0,0,1,286.51,480.19Z" />
        <rect fill="#2f2e41" x="284.81" y="496.92" width="15.35" height="1" transform="translate(-250.58 692.51) rotate(-79.3)" />
        <rect x="308.82" y="490.07" width="1" height="21.72" transform="translate(-67.09 48.24) rotate(-8.05)" fill="#2f2e41"/>
        <path fill="#f9b499" d="M352.43,470.52c23.47-10.94,37.37-6.83,37.37-6.83l3,.21c2.61,3.63,9.62,9.44,9.62,9.44a39.92,39.92,0,0,1,9.12,10.38,16.28,16.28,0,0,1,2.47,7.59l.62,9.24v32.14c-9.12-1-24.86-3.42-24.86-3.42l-3.65-28.45a3.44,3.44,0,0,0-1.51-2.42l-7.48-4.92c0,2.79-2.14,8.36-2.14,8.36l-7.5,23.78c-10.72-.85-25.72-6-25.72-6L345,496.91c-11.79-3-31.58-14.91-31.46-14.93A148.48,148.48,0,0,0,352.43,470.52Z" />
        <path d="M313.55,482l10.06,5.71s25.26-8.45,33-15.79l13,8.82a14.47,14.47,0,0,1,7.6,12.76l2,1.29s2.94-10.31-8-17.08c0,0-13.41-5.64-2.73-8.82,0,0,18.18-5.55,25.79-3.28l-1.38-1.69-3-.21s-10.27-2.83-30.64,4A142.07,142.07,0,0,1,313.55,482Z" fill="#f7a48b"/>
        <path fill="#f9b499" d="M403,366.66s-3.75,57.12,2,80.81c0,0-15.3-23.31-19.92-71.25C385.05,376.22,386.71,370.83,403,366.66Z" />
        <path fill="#543081" d="M389.8,333.25s18.27,11.92,13.18,33.41c0,0-15.87,4-17.93,9.56C385.05,376.22,381.71,347.71,389.8,333.25Z" />
        <path d="M389.8,333.25s18.27,11.92,13.18,33.41c0,0-15.87,4-17.93,9.56C385.05,376.22,381.71,347.71,389.8,333.25Z" opacity="0.2"/>
        <path fill="#543081" d="M326.39,315.18h-1.8l-8.74,46.7L314,370.49s34.21,33.77,34.95,33.77,36.81-21.19,36.81-21.19S381,348.3,389.8,333.25c0,0-11.41-6.64-18.7-8.81l-25.66-10.39Z" />
        <path d="M394,284.26v0Z" fill="#2f2e41"/>
        <path d="M403.33,294.42c-3.4-2.76-9.09-7.48-9.3-10.13l-.38,4c1.86,3.18,6.94,8.56,9.38,10.85a9.81,9.81,0,0,1,3,5.31c.75,3.92-1.36,5.33-1.36,5.33a1.28,1.28,0,0,0,0-.19,8.15,8.15,0,0,0-2.78-5.62c-3.86-3.44-7.35-8.72-9-11.42a47.1,47.1,0,0,1-3.45,9.66c-6.9,12.15-16.17,13.61-16.17,13.61a10,10,0,0,0-2.16,5.55s29,7.36,33.11-7.8a11.1,11.1,0,0,0,4.21-8.6A13.43,13.43,0,0,0,403.33,294.42Z" fill="#2f2e41"/>
        <path d="M421.08,533.28s10.39-67.68,72.72-53.69a34.18,34.18,0,0,1,18.75,11.46c4.11,4.95,9,11.19,11.67,15.72L542.91,532S458.54,539.05,421.08,533.28Z" fill="#2f2e41"/>
        <path d="M569.78,414.17,565,419.82c-14.23,18.84-35.73,9.41-35.73,9.41l-90.35-21.51c13.49-22.45,2.19-37.23,2.19-37.23l10.18,2.27,80.17,17.87a5.42,5.42,0,0,0,4.94-1.37L581,346.72l2.58,9.42,1.2,4.39C569.52,388.48,569.78,414.17,569.78,414.17Z" fill="#543081"/>
        <path d="M569.78,414.17,565,419.82c-14.23,18.84-35.73,9.41-35.73,9.41l-90.35-21.51c13.49-22.45,2.19-37.23,2.19-37.23l10.18,2.27,80.17,17.87a5.42,5.42,0,0,0,4.94-1.37L581,346.72l2.58,9.42,1.2,4.39C569.52,388.48,569.78,414.17,569.78,414.17Z" fill="#fff" opacity="0.5"/>
        <path d="M569.78,414.17,565,419.82c-14.23,18.84-35.73,9.41-35.73,9.41l-90.35-21.51c13.49-22.45,2.19-37.23,2.19-37.23l10.18,2.27a45.61,45.61,0,0,1,4.43,20.07s0,11.84,10.45,14.9c0,0,25.95,7.28,45.7,9.85,0,0,34.42,4.77,48.35-19.07l22.23-41.94,1.17-.43,1.2,4.39C569.4,388.86,569.78,414.17,569.78,414.17Z" fill="#fff" opacity="0.5"/>
        <path d="M584.78,360.53S566.37,393.88,570.34,422l2.17,28.51-66.86,32.57s14.29,16.57,18.57,23.72c0,0-19.43,13.71-24.57,22,0,0,113.43-9.15,131.71,54.85,0,0,33.15-56,54.86-70.28,0,0,13.72-8,10.29-48l-6.29-80.45s-1.43-27.27-25.71-45.27l-15-11.23Z" fill="#543081"/>
        <path d="M584.78,360.53S566.37,393.88,570.34,422l2.17,28.51-66.86,32.57s14.29,16.57,18.57,23.72c0,0-19.43,13.71-24.57,22,0,0,113.43-9.15,131.71,54.85,0,0,33.15-56,54.86-70.28,0,0,13.72-8,10.29-48l-6.29-80.45s-1.43-27.27-25.71-45.27l-15-11.23Z" fill="#fff" opacity="0.5"/>
        <path d="M595.29,322.91a37.73,37.73,0,0,1,6.24,18.21s20.67-14.28,28.38-18.21c0,0-6.39-17.34-3.74-24.62Z" fill="#f9b499"/>
        <path d="M595.29,322.91a40.88,40.88,0,0,1,4.7,9.94s9.38-4.44,16.06-16.84Z" fill="#f7a48b"/>
        <path d="M578.73,407.72s48.24-66.6,70.83-79.33a30.89,30.89,0,0,1-6.83-8.69s-3.62-10.64-15-3.8c.29,1.11.64,2.31,1,3.49s.78,2.4,1.19,3.52c-3.67,2-7.43,4.22-11.32,6.58-6.14,3.71-13.24,8.26-17.06,11.63,0,0-10.33,5.9-14.26,32.29-.17,1.15-.37,2.28-.64,3.41C585.09,383.39,579.53,406.81,578.73,407.72Z" fill="#543081"/>
        <path d="M587.75,370.49s6.58-24.85,13.13-19a40.35,40.35,0,0,1,8.6,16.76l-1.89,2.28s-6.41-12.64-12.22-7.87Z" fill="#543081"/>
        <path d="M587.75,370.49s6.58-24.85,13.13-19a40.35,40.35,0,0,1,8.6,16.76l-1.89,2.28s-6.41-12.64-12.22-7.87Z" opacity="0.2"/>
        <path d="M579.3,343.81s10.21-3.41,13,10.52a37.33,37.33,0,0,1,9.25-13.21,34.4,34.4,0,0,0-3.43-13.22S582.29,335.35,579.3,343.81Z" fill="#543081"/>
        <path d="M579.3,343.81s10.21-3.41,13,10.52a37.33,37.33,0,0,1,9.25-13.21,34.4,34.4,0,0,0-3.43-13.22S582.29,335.35,579.3,343.81Z" opacity="0.1"/>
        <path d="M579.37,343.74c-.27.06-.11.25-.15.52-.21,1.48-.46,6.55,4.11,13.86l5.17,8.58a94.57,94.57,0,0,1,3.78-12.37S590.72,341.28,579.37,343.74Z" fill="#543081"/>
        <path d="M579.37,343.74c-.27.06-.11.25-.15.52-.21,1.48-.46,6.55,4.11,13.86l5.17,8.58a94.57,94.57,0,0,1,3.78-12.37S590.72,341.28,579.37,343.74Z" opacity="0.2"/>
        <path d="M585.7,362.05s-6.18,26.31-7,45.67c1.63-4.55,3.21-9.51,4.62-14.86a179.9,179.9,0,0,0,4.4-22.37l.75-3.79Z" fill="#543081"/>
        <path d="M524.22,506.77s22.45-19.38,54.51-26l29-8.82s19.12-5.76,26.76-19.42l34.8-57.65L519.75,479.61l-14.1,3.44S515.51,495,524.22,506.77Z" fill="#fff" opacity="0.5"/>
        <path d="M571.7,439.93l-.63-8.31s16.51-3.71,27.54-22.85l31.3-38.28s20.6-30.85,38.6-13.29c0,0-71.43,71.85-71.43,72.71S571.7,439.93,571.7,439.93Z" fill="#fff" opacity="0.5"/>
        <path d="M441.08,370.49s-7.77,14.09-5.94,27.29c0,0,.51,10.35,3.74,9.94C438.88,407.72,452,388.67,441.08,370.49Z" fill="#543081"/>
        <path d="M441.08,370.49s-7.77,14.09-5.94,27.29c0,0,.51,10.35,3.74,9.94C438.88,407.72,452,388.67,441.08,370.49Z" fill="#fff" opacity="0.6"/>
        <path d="M373.3,315.86a11.6,11.6,0,0,0-2.2,8.58s3.39,19.47-4.91,19.64c0,0-8.29,1.19-14.39-13.2l-6.36-16.83s6.22-2.5,7-8C352.41,306.06,360.87,317.72,373.3,315.86Z" fill="#f9b499"/>
        <path d="M358.58,312.27s2.23,6.93,12.43,10.07a14.1,14.1,0,0,1,2.29-6.48A19.34,19.34,0,0,1,358.58,312.27Z" fill="#f7a48b"/>
        <path d="M322.17,361.5c-9.08.93-17.41-10.53-20-14.48a12.56,12.56,0,0,0-2.91-3.21,14,14,0,0,0-12-2.26,0,0,0,0,1,0-.07,99.34,99.34,0,0,0,14.34-14.37s18-20.45,26.21-8.74c0,0,7.25,10.63-1.12,31.44Z" fill="#543081"/>
        <path d="M322.17,361.5c-9.08.93-17.41-10.53-20-14.48a12.56,12.56,0,0,0-2.91-3.21,14,14,0,0,0-12-2.26,0,0,0,0,1,0-.07,99.34,99.34,0,0,0,14.34-14.37s18-20.45,26.21-8.74c0,0,7.25,10.63-1.12,31.44Z" opacity="0.2"/>
        <path d="M287.2,341.48s-4.39,9.3,11.44,13.89L300.57,345S296.11,339.49,287.2,341.48Z" fill="#543081"/>
        <path d="M287.2,341.48s-4.39,9.3,11.44,13.89L300.57,345S296.11,339.49,287.2,341.48Z" opacity="0.3"/>
        <path d="M237.93,458.12c4.56-5,15.07-15.72,27.76-23.7,0,0,16.47-18.67,23.77-30.75S314,370.49,314,370.49s28.49-4.8,19.63-55.74l7.61-.45s3.56,37.32,20.42,40.18c0,0,12.43,3,13.71-12V326l3.14,1.38a123.55,123.55,0,0,1,1.86,26.4c-.42,15.64,2,27.53,5.39,29.3,0,0,5.62,44.84,20.58,66.48,0,0,16.46,21,7.46,38.14,0,0-6-24.21-24-24,0,0-16.29-3.85-43.94,9.86,0,0-68.59,28.17-107.8-10A3.89,3.89,0,0,1,237.93,458.12Z" fill="#2f2e41"/>
        <path d="M237.93,458.12c4.56-5,15.07-15.72,27.76-23.7,0,0,16.47-18.67,23.77-30.75S314,370.49,314,370.49s28.49-4.8,19.63-55.74l7.61-.45s3.56,37.32,20.42,40.18c0,0,12.43,3,13.71-12V326l3.14,1.38a123.55,123.55,0,0,1,1.86,26.4c-.42,15.64,2,27.53,5.39,29.3,0,0,5.62,44.84,20.58,66.48,0,0,16.46,21,7.46,38.14,0,0-6-24.21-24-24,0,0-16.29-3.85-43.94,9.86,0,0-68.59,28.17-107.8-10A3.89,3.89,0,0,1,237.93,458.12Z" opacity="0.2"/>
        <ellipse cx="372.77" cy="364.76" rx="7.52" ry="16.55" fill="#543081"/>
        <ellipse cx="372.77" cy="364.76" rx="7.52" ry="16.55" fill="#fff" opacity="0.2"/>
        <circle cx="379.4" cy="364.9" r="6.63" fill="#543081"/>
        <circle cx="379.4" cy="364.9" r="6.63" opacity="0.4"/>
        <path d="M491.72,448c-26.42,0-50.76-16.58-67.54-31.81a238.91,238.91,0,0,1-35.45-40.92l.84-.55a237,237,0,0,0,35.3,40.75c19.52,17.71,49.37,37.29,80.66,29.94.23,0,19.9-4,53.18-56.48l8.73-12.94.83.56-8.72,12.93c-33.6,52.94-53,56.77-53.82,56.91A61.43,61.43,0,0,1,491.72,448Z" fill="#2f2e41"/>
        <path d="M208.35,331.16A52.48,52.48,0,0,0,225,310.83c3.15-33.3-3.32-64.67-3.32-64.67l-.35-5.08-24.89-7.72-19.81,40c-10.69,27.7,5.26,36.46,5.26,36.46-9.11-3.16-10.16-12.27-10.16-12.27-5.61,19.63,7,31.37,7,31.37,8.06,9.12,5.43,11.4,5.43,11.4-3-6-14.2-6.31-14.2-6.31s-1.21,28.89,24.58,40.67a12.58,12.58,0,0,0,9.28.5c8.44-2.92,23.64-13.69,20.83-53.44,0,0-.27.32-.75.84A28.73,28.73,0,0,1,209,331Z" fill="#543081"/>
        <path d="M208.34,331.16,209,331a28.76,28.76,0,0,0,14.89-8.47c.48-.52.74-.84.74-.84,2.82,39.75-12.39,50.52-20.83,53.44a12.55,12.55,0,0,1-9.27-.5C168.75,362.86,170,334,170,334s11.22.35,14.2,6.31c0,0,2.63-2.28-5.44-11.4,0,0-12.61-11.74-7-31.37,0,0,1.06,9.1,10.14,12.26-.58-.34-11-6.6-8.54-24.2v0a48.2,48.2,0,0,1,1.59-7.24.06.06,0,0,1,0,0q.71-2.37,1.7-4.95l.72-1.45h0l19.06-38.47,0,0,24.88,7.72.35,5.08s1.49,7.2,2.69,18.37v0a236,236,0,0,1,.64,46.28A52.45,52.45,0,0,1,208.34,331.16Z" fill="#fff" opacity="0.4"/>
        <path d="M224.35,264.53v0c-25.11-13.69-50.37,20.12-51,21v0a25.14,25.14,0,0,1,1.6-7.26.06.06,0,0,1,0,0c.65-1.95,1.48-4.11,2.42-6.4h0c6.48-15.76,18.55-37.55,19.06-38.47l0,0,24.88,7.72.35,5.08S223.15,253.36,224.35,264.53Z" fill="#fff" opacity="0.4"/>
        <path d="M81.37,333a52.07,52.07,0,0,1,13,20.33s4.38,13.94,22.71,20.12c0,0,11,3.19,11.36,9,0,0-8-4.39-15.74-1.6,0,0,23.51,33.48,43,15.15,0,0,11.28-9.74,0-32.88l-5.58,6.38s-1-7,2-12.16l-17.74-27.84H124l7.94-8s-5.32-10.16-7.94-12.55c0,0-18.56-4.7-28.52,2.73l-2.13-7.09L79,301.17l-13.55,9Z" fill="#543081"/>
        <path d="M81.37,333a52.07,52.07,0,0,1,13,20.33s4.38,13.94,22.71,20.12c0,0,11,3.19,11.36,9,0,0-8-4.39-15.74-1.6,0,0,23.51,33.48,43,15.15,0,0,11.28-9.74,0-32.88l-5.58,6.38s-1-7,2-12.16l-17.74-27.84H124l7.94-8s-5.32-10.16-7.94-12.55c0,0-18.56-4.7-28.52,2.73l-2.13-7.09L79,301.17l-13.55,9Z" fill="#fff" opacity="0.4"/>
        <path d="M93.39,350.86s-3.85-43.85,34-37.35a27.54,27.54,0,0,0-15.76-12.34c-11-3.19-17.14,0-17.14,0l-20.72,5L81.37,333A50.2,50.2,0,0,1,93.39,350.86Z" fill="#fff" opacity="0.4"/>
        <path d="M171.49,426.88q-2.51-2.67-4.89-5.39l-6.68-14.38c-2-4.27-3.88-8.58-6.12-12.67-1.1-2.06-2.32-4.06-3.53-6.08s-2.42-4-3.68-6q-7.49-11.94-15.92-23.26l-.36-.47c-5.52-7.36-11.28-14.58-17.47-21.37h0v0a85.39,85.39,0,0,0-7.47-8.34c-2.65-2.65-5.43-5.16-8.24-7.63s-5.71-4.87-8.63-7.22-5.88-4.66-8.89-6.89c3,2.19,6,4.46,9,6.76s5.88,4.67,8.74,7.1,5.67,4.92,8.37,7.54a84.17,84.17,0,0,1,7.62,8.27c5.72,6.1,11.06,12.51,16.2,19.08.67.85,1.34,1.71,2,2.57,5.75,7.47,11.18,15.2,16.32,23.11,1.28,2,2.55,4,3.78,6s2.5,4,3.64,6.09c2.35,4.14,4.29,8.45,6.35,12.67Z" fill="#2f2e41"/>
        <path d="M627.77,291.76c-3.68,20.56-18.41,35.13-32.91,32.53-5.78-1-10.65-4.64-14.19-9.91-.31-.47-.61-.95-.89-1.44-4.68-7.88-6.58-18.94-4.5-30.58,3.68-20.56,18.42-35.13,32.91-32.53S631.45,271.19,627.77,291.76Z" fill="#f9b499"/>
        <path d="M576.05,278.78s5.74-12.44,13.89-12.87c0,0,18.8,21.87,38.65,18.11L591.1,254.46S579.31,261.86,576.05,278.78Z" fill="#f7a48b"/>
        <path d="M587.79,257.31s-23.1,24-15,42.13a14,14,0,0,1-3.94-14.39s-8.38-8.82-.6-24.83c0,0,11.39-19.49,33.26-22.28,0,0-6.81-5.71-4.64-14.56a15.32,15.32,0,0,1,2.08-4.63,29.42,29.42,0,0,1,6.56-7.49s-11.14,16-1.39,25.52c0,0,4.13,1.59,1.39-4.41,0,0-7.88-19.6,10.33-24.1,0,0,10.93-4.5,23.79,7.71,0,0,25.29,29.36-4.93,34.71,0,0,27.47,41.58-9,55.73-.06-.8-.18-1.66-.21-2.5a40.61,40.61,0,0,1,.29-5.63c.5-1.64,1-3.42,1.4-5.31a41.75,41.75,0,0,0,1.35-9S604.92,281.28,587.79,257.31Z" fill="#2f2e41"/>
        <ellipse cx="629.91" cy="291.49" rx="10.21" ry="6.51" transform="translate(11.59 606.82) rotate(-51.85)" fill="#f9b499"/>
        <path d="M501.46,457.53s4.36,28.34,19.24,33.62C520.7,491.15,524.82,464.81,501.46,457.53Z" fill="#543081"/>
        <path d="M501.46,457.53s4.36,28.34,19.24,33.62C520.7,491.15,524.82,464.81,501.46,457.53Z" fill="#fff" opacity="0.6"/>
        <path d="M520.32,481.87l-6.91,1.05c-18.28,2.67-26.07,15.54-26.07,15.54-4.4,7.79-7.62,5.59-7.62,5.59-5.24-2.88,8-18.63,8-18.63-6.6,2.54-13.58,11.68-13.58,11.68-6.3,10-9.1,7.62-9.1,7.62-4.91-4.4,12.19-21.16,12.19-21.16-8.3,3.73-15.75,11.68-15.75,11.68-5.25,6.6-8.29,5.59-8.29,5.59-6.44-3.9,14.39-19.64,14.39-19.64-8.64,1.69-16.6,10.84-16.6,10.84-6.26,7.44-9.81,5.58-9.81,5.58-5.59-7.79,21.67-23.27,21.67-23.27,12.35-9.73,45-13.46,45-13.46S516.73,466,520.32,481.87Z" fill="#f9b499"/>
        <path d="M453,480.72S451,471,469.77,470.26c0,0-3.22,1.8-7,4.08Z" fill="#f7a48b"/>
        <path d="M503.8,461.42s9.17,6.54,11.61,21.5l4.91-1.05s-2.61-13.56-12.49-21Z" fill="#f7a48b"/>
        <path d="M501.46,457.53s19,4.88,19.24,33.62l88.78-28s14.64-6.16,19.77-13.86L668.51,396s20.53-25.66,0-38.75c0,0-14.37-10.37-33.36,13.29l-38.23,51.38s-4.82,9-18.19,12.57Z" fill="#543081"/>
        <path d="M501.46,457.53s19,4.88,19.24,33.62l88.78-28s14.64-6.16,19.77-13.86L668.51,396s20.53-25.66,0-38.75c0,0-14.37-10.37-33.36,13.29l-38.23,51.38s-4.82,9-18.19,12.57Z" fill="#fff" opacity="0.5"/>
        <ellipse cx="369.78" cy="282.35" rx="24.33" ry="33.86" fill="#f9b499"/>
        <path d="M332.05,264.2l-16,26s-5.67,9,3.09,18l7.21,6.95,19.05-1.13s6.73-3,7-8a38.46,38.46,0,0,1-5.64-12.66l3-11s2.31,2.95,2.62,3.22-1.51-8.2,4.38-9.16A35.15,35.15,0,0,0,375.74,259a.78.78,0,0,1,1.15-.33,4,4,0,0,0,2.44.76,1.35,1.35,0,0,1,1.39,1.5c-.65,5.4-1.2,21,13.93,22.89a14.9,14.9,0,0,0,12.72-4.4l.15-.16a.38.38,0,0,0-.39-.62c-2.78.88-8.86,1.64-9.62-8.12,0,0,.34-32.46-10.72-22,0,0-13.27-20.15-41.35-2.08,0,0-19.75,11.2-26.82,18.68-.73.78-1.48,1.53-2.26,2.26-4.49,4.16-18.32,18.49-3.14,24.56,0,0-10-11.57-.43-17.29Z" fill="#2f2e41"/>
        <ellipse cx="344.34" cy="285.57" rx="6.31" ry="8.24" transform="translate(-84.83 159.33) rotate(-23.28)" fill="#f9b499"/>
        <path d="M300.57,345s-5.76,34.51-14.81,59.27l-8.34,21.62a13,13,0,0,1-9.31,8.14c-.29.06-.58.11-.89.15a37.62,37.62,0,0,0-5.58,1.17c-5.63,1.75-15.66,5.77-14.42,11.93,0,0-8.86,7.07-3.86,9.07,0,0-3.06,3-1.53,4.14,0,0,1.53,2.43,5.39-1.43,0,0-.28,4.43,4.72,1.29l6.7-5.68a7.63,7.63,0,0,1,1.64-1.05c2.37-1.1,8.72-3.82,12-3a1.38,1.38,0,0,1,1,1.67,6,6,0,0,1-2.3,3s-7.09,3.52-4.61,6.57a1.14,1.14,0,0,0,.92.44c2.31,0,11.91-1.12,16.42-17.08,0,0,3.43-8.36,6.22-12,0,0,19-30,24.05-62.78l2.13-10.06s-7.49-3-13.94-13.41Z" fill="#f9b499"/>
        <path d="M298.64,355.37s11.81,7.08,17,7.19l.45-2.13A36.7,36.7,0,0,1,302.18,347a21.79,21.79,0,0,0-1.61-2Z" fill="#f7a48b"/>
        <path d="M399.67,359.29s-6.66,5.4-3.66,10.12c0,0,3.89,19.38,12.23,14.62l9.2-14.41-6.22-11.35Z" fill="#f7a48b"/>
        <path d="M442.23,372.37l-5.9-1.14a18.23,18.23,0,0,1-8-3.72,38.22,38.22,0,0,1-3.78-3.5,52,52,0,0,0-19.63-12.21s-10-3.61-17.06-1.11c0,0-18,4.44-13.32,8.65,0,0,1.16,2.1,10.28-.47,0,0,8.4-2.92,16.33,1.08a13.27,13.27,0,0,1,4.4,3.65l5.44,6.89s-29-7.78-29.44,0c0,0-.19,3.31,7.6,4.46a28.36,28.36,0,0,1,6.72,1.78,38.51,38.51,0,0,1,8.46,4.6,33.45,33.45,0,0,0,6.6,4.12,60.26,60.26,0,0,0,17.36,5,19.69,19.69,0,0,1,3.57.74c3.22,1,9.52,3.27,11.79,6.19A32.57,32.57,0,0,0,442.23,372.37Z" fill="#f9b499"/>
        <path d="M437.42,393.28s7.56-8.61,2.89-21.28l1.92.37s6.2,9.75,1.42,25A32.15,32.15,0,0,0,437.42,393.28Z" fill="#f7a48b"/>
        <path d="M247.22,447.27a18.78,18.78,0,0,1,2.2-2.17,29.09,29.09,0,0,1,2.44-1.86,22.11,22.11,0,0,1,2.66-1.57,11,11,0,0,1,2.91-1.06c-1.71,1.15-3.33,2.31-5,3.45A52.52,52.52,0,0,1,247.22,447.27Z" fill="#f7a48b"/>
        <path d="M243.36,456.34a15.45,15.45,0,0,1,1.69-2.45,24.35,24.35,0,0,1,2-2.15A17.56,17.56,0,0,1,252,448.4c-1.37,1.44-2.79,2.73-4.2,4.07S244.93,455.1,243.36,456.34Z" fill="#f7a48b"/>
        <path d="M247.22,459.05a17.7,17.7,0,0,1,1.86-2.67,22.67,22.67,0,0,1,2.23-2.37,17.94,17.94,0,0,1,2.57-2,9.16,9.16,0,0,1,3-1.32,41.82,41.82,0,0,0-4.9,4.07C250.41,456.17,248.9,457.66,247.22,459.05Z" fill="#f7a48b"/>
        <circle cx="628.78" cy="293.32" r="3.15" fill="#2f2e41"/>
        <path d="M198.11,276c-.58,8.16-.81,16.33-.87,24.5,0,16.35.59,32.69,1.68,49,.15,2,.27,4.09.34,6.16l.08,6.14c.07,4.09,0,8.18,0,12.28-.07,8.19-.21,16.38-.57,24.56-.64,15.46-2.08,34.1-4.11,49.87-.75-.6-1.49-1.22-2.23-1.85,2.16-15.37,3.38-32.81,4.24-48.12.47-8.15.73-16.32.91-24.48.06-4.09.16-8.17.14-12.26V355.7c-.05-2-.13-4-.26-6.1-.85-16.35-1.24-32.72-1-49.09.17-8.18.52-16.36,1.22-24.52l.61-6.1.82-6.09c.33-2,.74-4,1.1-6s1-4,1.49-5.95c-.46,2-1,4-1.4,6s-.72,4-1,6.05l-.74,6.09Z" fill="#2f2e41"/>
        <path d="M175.88,275.36s16.87-26.05,35.8-25.52c0,0-10.34-8.59-12.62-13.32,0,0,14,8.76,22.61,9.64,0,0,17.17-4.56,23-15.43,0,0-12.27-1-16.13-4.55h13.85s8.24.7,11.39-9.29c0,0,8.24-23,10.34-25.76,0,0-35.4-2.81-33.12,13.32,0,0-3.16-.53-2.11-8.42C228.85,196,178,223.72,175.88,275.36Z" fill="#543081"/>
        <path d="M177.79,261a48.51,48.51,0,0,1,6.46-12.58,103,103,0,0,1,8.9-11.08c1.63-1.72,3.24-3.45,5-5.07s3.46-3.23,5.23-4.81a140.08,140.08,0,0,1,11.09-8.89,116.73,116.73,0,0,1,12.16-7.37c4.15-2.25,8.28-4.54,12.45-6.76l12.48-6.7,12.54-6.61-12.3,7-12.35,6.94c-4.11,2.33-8.26,4.59-12.39,6.89a118,118,0,0,0-12,7.39,156.07,156.07,0,0,0-21.31,18.48,105.08,105.08,0,0,0-9.06,10.82A50.55,50.55,0,0,0,177.79,261Z" fill="#2f2e41"/>
        <path d="M47.1,387.24s26.11-14.75,27.3-32.68c0,0-9-5.78-9-11.15,0,0,5.78,6.68,9,7.92,0,0,6-21.07,15.74-32.23,0,0-10-8.17-10.56-12,0,0,9,5.86,15.94,4.53,0,0,9-7.12,28.52-2.73,0,0-13.78-20.13-37.49-17.34,0,0-18.92,2.39-32.67,11.16,0,0,7.57-.14,9.56,1.82,0,0-27.89.37-25.3,28.27l2.19,15.34,5.38-.2-4.38,5.18S46.9,377.68,47.1,387.24Z" fill="#543081"/>
        <path d="M47.1,387.24A15.46,15.46,0,0,0,49,383.68a31.76,31.76,0,0,0,1.29-3.82,54.62,54.62,0,0,0,1.5-7.93,92.44,92.44,0,0,0,.46-16.13,77,77,0,0,1,3.1-32.19A48.32,48.32,0,0,1,63,309.2a36.67,36.67,0,0,1,12.54-10.34,43.43,43.43,0,0,1,15.64-4.34,70.71,70.71,0,0,1,16.19.58A74.45,74.45,0,0,0,91.18,295a42.92,42.92,0,0,0-15.34,4.53,37,37,0,0,0-12.17,10.22,47.36,47.36,0,0,0-7.36,14.17c-.47,1.25-.8,2.56-1.19,3.84s-.66,2.6-.91,3.92a70.74,70.74,0,0,0-1.2,8,72,72,0,0,0-.36,8c0,2.65.32,5.39.38,8.08A92.68,92.68,0,0,1,52.28,372a54.91,54.91,0,0,1-1.72,7.93,33.38,33.38,0,0,1-1.41,3.81A15.52,15.52,0,0,1,47.1,387.24Z" fill="#2f2e41"/>
        <path d="M629.91,322.91s-1.5-4.11-2.2-7a17.47,17.47,0,0,1,3.81-1.75C633.59,313.5,629.91,322.91,629.91,322.91Z" opacity="0.2"/>
        <path d="M616.64,337.76C592.2,373.42,575.84,377.2,570,377.2a8.81,8.81,0,0,1-2.36-.26l-.1,0a11.43,11.43,0,0,1-6.27-7.48c-4.67-15.74,13.2-47.54,18.51-56.48.28.49.58,1,.89,1.44-5.62,9.63-22.3,40-18,54.62a9.85,9.85,0,0,0,5.41,6.51c.92.22,18.33,3.64,47.31-38.64.23-.3,20.61-26.6,17.29-38.38a6.65,6.65,0,0,0-4.4-4.64.75.75,0,0,1,.53-1.41,8.21,8.21,0,0,1,5.32,5.64C637.68,310.59,617.5,336.66,616.64,337.76Z" fill="#2f2e41"/>
        <g opacity="0.2">
            <path d="M616.64,337.76C592.2,373.42,575.84,377.2,570,377.2a8.81,8.81,0,0,1-2.36-.26l-.1,0a11.43,11.43,0,0,1-6.27-7.48c-4.67-15.74,13.2-47.54,18.51-56.48.28.49.58,1,.89,1.44-5.62,9.63-22.3,40-18,54.62a9.85,9.85,0,0,0,5.41,6.51c.92.22,18.33,3.64,47.31-38.64.23-.3,20.61-26.6,17.29-38.38a6.65,6.65,0,0,0-4.4-4.64.75.75,0,0,1,.53-1.41,8.21,8.21,0,0,1,5.32,5.64C637.68,310.59,617.5,336.66,616.64,337.76Z"/>
        </g>
        
        <circle cx="717.93" cy="232.62" r="47.67" fill="#ebebeb"/>
        <circle cx="720.11" cy="233.64" r="45.48" transform="translate(-16.3 409.27) rotate(-31.39)" fill="#dbdbdb"/>
        <circle cx="720.12" cy="233.66" r="42.61" transform="translate(45.7 577.64) rotate(-45)" fill="#fff"/>
        <rect x="719.72" y="194.18" width="0.82" height="6.46" fill="#c7c7c7"/>
        <rect x="701.58" y="199.03" width="0.82" height="6.46" transform="translate(-7.08 378.09) rotate(-30)" fill="#c7c7c7"/>
        <rect x="688.26" y="212.3" width="0.82" height="6.46" transform="translate(157.68 704.17) rotate(-60)" fill="#c7c7c7"/>
        <rect x="680.63" y="233.29" width="6.46" height="0.82" fill="#c7c7c7"/>
        <rect x="685.5" y="251.47" width="6.46" height="0.82" transform="translate(-33.67 378.11) rotate(-30)" fill="#c7c7c7" />
        <rect x="698.74" y="264.7" width="6.46" height="0.82" transform="translate(121.64 740.78) rotate(-60.03)" fill="#c7c7c7"/>
        <rect x="719.72" y="266.76" width="0.82" height="6.46" fill="#c7c7c7"/>
        <rect x="737.88" y="261.89" width="0.82" height="6.46" transform="translate(-33.65 404.66) rotate(-30)" fill="#c7c7c7"/>
        <rect x="751.12" y="248.58" width="0.82" height="6.46" transform="translate(157.69 776.75) rotate(-60)" fill="#c7c7c7"/>
        <rect x="753.19" y="233.29" width="6.46" height="0.82" fill="#c7c7c7"/>
        <rect x="748.34" y="215.15" width="6.46" height="0.82" transform="translate(-7.05 404.8) rotate(-30.01)" fill="#c7c7c7"/>
        <rect x="735.09" y="201.88" width="6.46" height="0.82" transform="translate(194.25 740.83) rotate(-60.03)" fill="#c7c7c7"/>
        <polygon points="720.76 197.11 722.16 236.58 718.11 236.52 719.66 197.09 720.76 197.11" fill="#c7c7c7"/>
        <polygon points="745.34 219.94 718.79 236.88 716.85 233.34 744.82 218.97 745.34 219.94" fill="#a6a6a6"/>
        <path d="M721.52,233.7a1.39,1.39,0,1,1-1.39-1.39,1.39,1.39,0,0,1,1.39,1.39Z" fill="#c7c7c7"/>
        <g id="e8cf702c-3efb-4896-bcc4-e8b159dad7d1" data-name="freepik--Heart--inject-88">
            <path d="M521.38,220a140.84,140.84,0,0,1-45.22,39.25A140.84,140.84,0,0,1,430.94,220C415,199,415.8,166,438.59,158.86,463.78,151,476.16,189,476.16,189s12.56-38.16,37.59-30C536.45,166.29,537.31,199,521.38,220Z" fill="#543081"/>
            <polygon points="458.39 242.7 451.51 214.1 447.04 224.18 419.31 224.18 419.31 220.95 444.94 220.95 452.44 204.1 457.79 226.39 465.35 180.2 472.03 216.75 477.33 201.49 485.65 226.47 487.5 220.95 533.05 220.95 533.05 224.16 489.82 224.16 485.65 236.65 477.27 211.51 471.08 229.41 465.53 199.1 458.39 242.7" fill="#543081"/>
            <polygon points="458.39 242.7 451.51 214.1 447.04 224.18 419.31 224.18 419.31 220.95 444.94 220.95 452.44 204.1 457.79 226.39 465.35 180.2 472.03 216.75 477.33 201.49 485.65 226.47 487.5 220.95 533.05 220.95 533.05 224.16 489.82 224.16 485.65 236.65 477.27 211.51 471.08 229.41 465.53 199.1 458.39 242.7" fill="#fff" opacity="0.7"/>
        </g>

        <defs>
            <linearGradient
            id="paint0_linear_1_79"
            x1="296.527"
            x2="105.126"
            y1="186.371"
            y2="167.19"
            gradientUnits="userSpaceOnUse"
            >
            <stop stopColor={PRIMARY_MAIN} />
            <stop offset="1" stopColor={PRIMARY_DARK} />
            </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

export default memo(ComingSoonIllustration);
