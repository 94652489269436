import React, { useRef, useState } from 'react';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import cx from 'classnames'
import { Card, CircularProgress, Grid, InputBase } from '@mui/material';
import Iconify from '../iconify';
const Searchbar = (props) => {

    const {onRequestSearch, disabled, loading, waitTime=1000} = props

    const classes = useStyles()

    const timerRef = useRef()
    const [value, setValue] = useState('')
    const [focus, setFocus] = useState(false)


    const actions = {
        onStartCountDown: () => {
            if(timerRef.current) clearTimeout(timerRef.current)
            timerRef.current = setTimeout(actions.onInnerRequestSearch, waitTime);
        },
        onStopCountDown: () => {
            if(timerRef.current) clearTimeout(timerRef.current)
        },
        onUpdateFocus: (value) => setFocus(value),
        onUpdateSearchValue: (val) => {
            //if(!open) setOpen(true)
            setValue(val.currentTarget.value)
        },
        onInnerRequestSearch: () => {
            if(timerRef.current) clearTimeout(timerRef.current)
            onRequestSearch(value)
        }
    }


    return ( 
        <Card className={cx({
            [classes.searchbox]:true,
            [classes.searchbox_focus]:focus
        })}>
            <Grid container wrap='nowrap' alignItems='center'>
                <Grid item>
                    <div className={classes.search_icon}><Iconify icon='bi:search'/></div>
                </Grid>
                <Grid item xs>
                    <div className={classes.input}>
                        <InputBase  fullWidth value={value} disabled={disabled}
                        onFocus={() => actions.onUpdateFocus(true)} 
                        onBlur={() => actions.onUpdateFocus(false)} inputProps={{onKeyPress:actions.onKeyPress}}
                        onKeyUp={actions.onStartCountDown} onKeyDown={actions.onStopCountDown}
                        onChange={actions.onUpdateSearchValue} placeholder='Buscar...' />
                    </div>
                </Grid>
                {loading && <Grid item><CircularProgress size={24}/></Grid>}
            </Grid>
        </Card>
    );
}

const useStyles = makeStyles(theme => ({
    searchbox:{
        //border:`2px solid ${grey[200]}`,
        margin:'auto',
        //marginTop:16,
        //background:grey[100],
        boxSizing:'border-box',
        width:'100%',
        padding:8,
        borderRadius:8,
        //boxShadow:'none',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        }
    },
    searchbox_focus:{
        border:`2px solid ${theme.palette.primary.main}`,
        //background:'white'
    },
    search_btn:{
        color:'white',
        background:theme.palette.primary.main,
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    input:{
        fontWeight:600,
        paddingRight:8,
    },
    search_icon:{
        color:grey[400],
        padding:'2px 8px'
    },

}))
 
export default Searchbar;