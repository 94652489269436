import { Grid, Typography } from "@mui/material"
import { makeStyles } from '@mui/styles';
import { CARDS, catalogs } from "../../../utils/catalogs";

const PaymentType = ({order}) => {

    const classes = useStyles()

    

    let payment_type = catalogs.payments_types.find(el=>el.value === order?.store_payment_data?.store_payment_type_id)
    //const type = catalogs.payments_types.find(el=>el.value === type_id)

    let contentView = null
    let paymentText = payment_type?.label



    if(payment_type?.value === 1){
        const _card = CARDS[order?.store_payment_data?.payment_method?.brand]
        paymentText = `${_card?.pattern} ${order?.store_payment_data?.payment_method?.last4}`
        payment_type.img = _card?.logo
    }

    contentView = (
        <div>
            <Grid container alignItems="center" spacing={1} justifyContent='center'>
                <Grid item>
                    <div className={classes.image_container}>
                        <img src={payment_type?.img} alt='' className={classes.image}/>
                    </div>
                </Grid>
                <Grid item >
                    <Typography align="center" variant="subtitle1">{paymentText}</Typography>
                </Grid>
            </Grid>
        </div>
    )

    return(
        <div style={{width:'100%'}}>
            {contentView}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    image_container:{
        position:'relative',
        width:64,
        height:64,
        //border:'1px solid black',
        margin:'0px 16px'
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'contain'

    }
}))


export default PaymentType