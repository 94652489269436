import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { blueGrey, indigo } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';


const AnalyticsSimpleTable = ({
    head=[],
    data=[]
}) => {
    return(
        <Table>
            <TableHead >
                <TableRow>
                    {head.map(item => {
                        return(
                            <TableCell key={item} sx={{
                                background: theme => theme.palette.primary.main,
                                color:'white',
                                //py:0,
                                //fontSize:12
                            }}>
                                {item}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row, key) => {
                    return(
                        <TableRow key={`rowitem-${key}`}>
                            {row.map((el, idx) => {
                                return(
                                    <TableCell key={`rowcolitem-${key}-${idx}`} sx={{
                                        //py:0,
                                        border:`1px solid ${blueGrey[50]}`,
                                        //fontSize:12
                                    }}>
                                        <Typography>
                                            {el}
                                        </Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
                
            </TableBody>
        </Table>
    )
}
 
export default AnalyticsSimpleTable;