

// Food
import { amber, blue, deepOrange, green, orange, red } from '@mui/material/colors'
import cerealIcon from '../assets/icons/food/cereals.svg'
import dairyIcon from '../assets/icons/food/dairy.svg'
import fatIcon from '../assets/icons/food/fat.svg'
import fruitsIcon from '../assets/icons/food/fruits.svg'
import legumesIcon from '../assets/icons/food/legumes.svg'
import proteinIcon from '../assets/icons/food/protein.svg'
import sugarIcon from '../assets/icons/food/sugar.svg'
import vegetablesIcon from '../assets/icons/food/vegetables.svg'
import waterIcon from '../assets/icons/food/water.svg'

export const catalogs = {
    patient_types:[
        {value:1, label:'Colaborador'},
        {value:2, label:'Familiar'},
    ],
    patient_segment_types:[
        {value:1, label:'Voluntario'},
        {value:2, label:'Riesgo'},
    ],
    offices:[
        {value:1, label:'Gran Sur'},
        {value:2, label:'Santa Fé'},
    ],
    mobile_country_codes:[
        {value:1, label:'+52', country:'México', icon:null},
        {value:2, label:'+57', country:'Colombia', icon:null},
        {value:3, label:'+1', country:'Estados Unidos', icon:null},
    ],
    equivalents:[
        {value:1, key:'vegetables', color:'#84A756', bgcolor:"#E6EDDD", label:'Verduras', calories:25, icon:vegetablesIcon, proteins:2, lipids:0, carbs:4, carbs_count:0},
        {value:2, key:'fruits', color:'#A76BA1', bgcolor:"#EDE1EC", label:'Frutas', calories:60, icon:fruitsIcon, proteins:0, lipids:0, carbs:15, carbs_count:1},
        {value:3, key:'cereals', color:'#C1AF70', bgcolor:"#F3EFE2", label:'Cereales y tubérculos', calories:70, icon:cerealIcon, proteins:2, lipids:0, carbs:15, carbs_count:1},
        {value:4, key:'legumes', color:'#5F7BAD', bgcolor:"#DFE5EF", label:'Leguminosas', calories:120, icon:legumesIcon, proteins:8, lipids:1, carbs:20, carbs_count:1.3},
        {value:5, key:'proteins', color:'#CD604D', bgcolor:"#F5DFDB", label:'Alimentos de origen animal', calories:75, icon:proteinIcon, proteins:7, lipids:5, carbs:0, carbs_count:0},
        {value:6, key:'dairy', color:'#6DA3B8',  bgcolor:"#E2EDF1", label:'Leche', calories:110, icon:dairyIcon, proteins:9, lipids:4, carbs:12, carbs_count:0.80},
        {value:7, key:'fats', color:'#E8CF7F', bgcolor:"#F3EFE2",label:'Aceites y grasas', calories:45, icon:fatIcon, proteins:0, lipids:5, carbs:0, carbs_count:0},
        {value:8, key:'sugar', color:'#8D8D8D', bgcolor:"#E8E8E8", label:'Azúcares', calories:40, icon:sugarIcon, proteins:0, lipids:0, carbs:10, carbs_count:0.7},
        {value:9, key:'water', color:'#80C9C4',  bgcolor:"#E6F4F3",label:'Agua', calories:0, icon:waterIcon, proteins:0, lipids:0, carbs:0, carbs_count:0},
    ],
    equivalents_smae:[
        {value:1, key:'smae_vegetables', color:'#84A756', bgcolor:"#E6EDDD", label:'Verduras', calories:25, icon:vegetablesIcon, proteins:2, lipids:0, carbs:4, carbs_count:0.3},
        {value:2, key:'smae_fruits', color:'#A76BA1', bgcolor:"#EDE1EC", label:'Frutas', calories:60, icon:fruitsIcon, proteins:0, lipids:0, carbs:15, carbs_count:1},
        {value:3, key:'smae_cereals_no_fat', color:'#C1AF70', bgcolor:"#F3EFE2", label:'Cereales y tubérculos', sublabel:'Sin grasa', calories:70, icon:cerealIcon, proteins:2, lipids:0, carbs:15, carbs_count:1},
        {value:4, key:'smae_cereals_fat', color:'#C1AF70', bgcolor:"#F3EFE2", label:'Cereales y tubérculos', sublabel:'Con grasa', calories:115, icon:cerealIcon, proteins:2, lipids:5, carbs:15, carbs_count:1},
        {value:5, key:'smae_legumes', color:'#5F7BAD', bgcolor:"#DFE5EF", label:'Leguminosas', calories:120, icon:legumesIcon, proteins:8, lipids:1, carbs:20, carbs_count:1.3},
        {value:6, key:'smae_proteins_very_low', color:'#CD604D', bgcolor:"#F5DFDB", label:'Alimentos de origen animal', sublabel:'Muy bajo', calories:40, icon:proteinIcon, proteins:7, lipids:1, carbs:0, carbs_count:0},
        {value:7, key:'smae_proteins_low', color:'#CD604D', bgcolor:"#F5DFDB", label:'Alimentos de origen animal', sublabel:'Bajo', calories:55, icon:proteinIcon, proteins:7, lipids:3, carbs:0, carbs_count:0},
        {value:8, key:'smae_proteins_normal', color:'#CD604D', bgcolor:"#F5DFDB", label:'Alimentos de origen animal', sublabel:'Moderado', calories:75, icon:proteinIcon, proteins:7, lipids:5, carbs:0, carbs_count:0},
        {value:9, key:'smae_proteins_high', color:'#CD604D', bgcolor:"#F5DFDB", label:'Alimentos de origen animal', sublabel:'Alto', calories:100, icon:proteinIcon, proteins:7, lipids:8, carbs:0, carbs_count:0},
        {value:10, key:'smae_dairy_light', color:'#6DA3B8', bgcolor:"#E2EDF1", label:'Leche', sublabel:'Descremada', calories:95, icon:dairyIcon, proteins:9, lipids:2, carbs:12, carbs_count:0.80},
        {value:11, key:'smae_dairy_semi_light', color:'#6DA3B8', bgcolor:"#E2EDF1", label:'Leche', sublabel:'Semidescremada', calories:110, icon:dairyIcon, proteins:9, lipids:4, carbs:12, carbs_count:0.80},
        {value:12, key:'smae_dairy_normal', color:'#6DA3B8', bgcolor:"#E2EDF1", label:'Leche', sublabel:'Entera', calories:150, icon:dairyIcon, proteins:9, lipids:8, carbs:12, carbs_count:0.80},
        {value:13, key:'smae_dairy_sugar', color:'#6DA3B8', bgcolor:"#E2EDF1", label:'Leche', sublabel:'Con azúcar', calories:200, icon:dairyIcon, proteins:8, lipids:5, carbs:30, carbs_count:0},
        {value:14, key:'smae_fats_no_proteins', color:'#E8CF7F', bgcolor:"#F3EFE2", label:'Aceites y grasas', sublabel:'Sin proteína', calories:45, icon:fatIcon, proteins:0, lipids:5, carbs:0, carbs_count:0},
        {value:15, key:'smae_fats_proteins', color:'#E8CF7F', bgcolor:"#F3EFE2", label:'Aceites y grasas', sublabel:'Con proteína', calories:70, icon:fatIcon, proteins:3, lipids:5, carbs:3, carbs_count:0.2},
        {value:16, key:'smae_sugars_no_fat', color:'#8D8D8D', bgcolor:"#E8E8E8", label:'Azúcares', sublabel:'Sin grasa',calories:40, icon:sugarIcon, proteins:0, lipids:0, carbs:10, carbs_count:0.7},
        {value:17, key:'smae_sugars_fat', color:'#8D8D8D', bgcolor:"#E8E8E8", label:'Azúcares', sublabel:'Con grasa',calories:85, icon:sugarIcon, proteins:0, lipids:5, carbs:10, carbs_count:0.7},
        {value:18, key:'smae_water', color:'#80C9C4', bgcolor:"#E6F4F3", label:'Agua', calories:0, icon:waterIcon, proteins:0, lipids:0, carbs:0, carbs_count:0},
    ],
    "user_types": [
        {"value":1,"label":"Administrador"},
        {"value":2,"label":"Nutriólogo"},
        {"value":3,"label":"Cliente"},
        {"value":4,"label":"Usuario"}
    ],
	"user_statuses": [
        {"value":1,"label":"Activo", color:'success',},
        {"value":2,"label":"Suspendido", color:'error',},
        {"value":3,"label":"Invitado", color:'info',},
        {"value":4,"label":"Registrado", color:'warning',}
    ],
	"genders": [
        {"value":1,"label":"Masculino"},
        {"value":2,"label":"Femenino"}
    ],
	"countries": [
        {"value":1,"label":"+52"}
    ],
	"physical_activity_levels": [
        {"value":1,"label":"Sedentario", male:1.3, female:1.3, description:'Personas que pasan varias horas al día sentados o con actividades con poco movimiento, por ejemplo: home office, trabajos y/o actividades sentados por tiempo prolongado, etcétera.'},
        {"value":2,"label":"Muy ligera", male:1.3, female:1.3, description:'Estirar, conducir, trabajos de oficina con ligero movimiento, etcétera.'},
        {"value":3,"label":"Ligera", male:1.6, female:1.5, description:'Realizar actividades como caminata a paso lento, actividades de limpieza doméstica, subir y bajar escaleras despacio, etcétera.'},
        {"value":4,"label":"Moderada", male:1.7, female:1.6, description:'Carpinteros, construcción, campo, granjas, mecánicos, estudiantes'},
        {"value":5,"label":"Pesada", male:2.1, female:1.9, description:'Deportistas de tiempo completo, bailarinas, soldados en servicio, etc'},
        {"value":6,"label":"Excepcional", male:2.4, female:2.2, description:'Leñadores, mujeres obreras, obreros de construcción pesada '}

    ],
	"client_statuses": [
        {"value":1,"label":"Activo"}
        ,{"value":2,"label":"Suspendido"}
    ],
	"office_statuses": [
        {"value":1,"label":"Activo"},
        {"value":2,"label":"Suspendido"}
    ],
	"appointment_statuses": [
        {"value":1,"label":"Agendada", color:'info', hexColor:'#00B8D9'},
        {"value":2,"label":"Reagendada", color:'primary', hexColor:'#F0780F'},
        {"value":3,"label":"Cancelada", color:'error', hexColor:'#D95D71'},
        {"value":4,"label":"Completada", color:'success', hexColor:'#36B37E'},
        {"value":5,"label":"Completada sin asistencia", color:'success', hexColor:'#36B37E'}
    ],
	"objectives": [
        {"value":1,"label":"Control de peso", color:'#4DD0E1'},
        {"value":2,"label":"Alimentación saludable", color:'#26A69A'},
        {"value":3,"label":"Actividad física", color:'#29B6F6'},
        {"value":4,"label":"Grupo de riesgo", color:'#EF5350'},
        {"value":5,"label":"Embarazo", color:'#F06292'},
        {"value":6,"label":"Lactancia", color:'#BA68C8'},
    ],
	"formulas": [
        {"value":1,"label":"Harris Benedict"},
        {"value":2,"label":"Mifflin St. Jeor"},
        {"value":3,"label":"Valencia"},
        {"value":4,"label":"Kath - McArdle MASA GRASA"},
        {"value":5,"label":"Kath - McArdle % DE GRASA"},
        {"value":6,"label":"Cunningham"}
    ],
	"category_types": [
        {"value":1,"label":"Producto"},
        {"value":2,"label":"Receta"}
    ],
	"tag_types": [
        {"value":1,"label":"Producto"},
        {"value":2,"label":"Receta"}
    ],
	"recipe_difficulties": [
        {"value":1,"label":"Fácil"},
        {"value":2,"label":"Medio"},
        {"value":3,"label":"Difícil"}
    ],
	"achievement_types": [
        {"value":1,"label":"Objetivo"},
        {"value":2,"label":"Meta"},
        {"value":3,"label":"Premio"}
    ],
	"diet_types": [
        {"value":1,"label":"Selección de alimentos"},
        {"value":2,"label":"SMAE"},
        {"value":3,"label":"Equivalentes Simplicado"},
        {"value":4,"label":"Menu"},
        {"value":5,"label":"Columnas"},
        {"value":6,"label":"Opciones"},
        {"value":7,"label":"Personalizada"}
    ],
    "appointment_types": [
        {"value":1,"label":"Zoom"},
        {"value":2,"label":"Meet"},
        {"value":3,"label":"Teams"},
        {"value":4,"label":"Videollamada de Whatsapp"},
        {"value":5,"label":"Llamada Telefónica"},
        {"value":6,"label":"Presencial en sitio"}
    ],
    "risk_levels": [
        {"value":1,"label":"Verde", color:green[600]},
        {"value":2,"label":"Amarillo", color:amber[600]},
        {"value":3,"label":"Rojo", color:red[600]},
    ],
    "imc_diagnostics": [
        {"value":1,"label":"Bajo de peso", min:0, max:18.5, color: blue[400]},
        {"value":2,"label":"Peso normal", min:18.5, max:25, color: green[400]},
        {"value":3,"label":"Sobrepeso", min:25, max:30, color: amber[400]},
        {"value":4,"label":"Obesidad I", min:30, max:35, color: orange[400]},
        {"value":5,"label":"Obesidad II", min:35, max:40, color: deepOrange[400]},
        {"value":6,"label":"Obesidad III", min:40, max:9999999999, color: red[400]}, 
    ],
    "icc_diagnostics": [
        {"value":1,"label":"Sin riesgo", color:green[400], min:{female:0, male:0}, max:{female:0.85, male:0.95}},
        {"value":2,"label":"Riesgo alto", color:red[400], min:{female:0.85, male:.95}, max:{female:99999999, male:99999999}},
    ],
    "digestion_statuses": [
        {"value":1,"label":"Función normal", min:0, max:5, color:green[400]},
        {"value":2,"label":"Disfunción leve", min:6, max:10, color:amber[400]},
        {"value":3,"label":"Disfunción moderada", min:11, max:14, color:orange[400]},
        {"value":4,"label":"Disfunción severa", min:15, max:999999, color:red[400]},
    ],
    "physical_conditions": [
        {"value": 1,"label": "Poca condición",min:0, max:5, color:red[400]},
        {"value": 2,"label": "Moderada",min:6, max:14, color:blue[400]},
        {"value": 3,"label": "Buena condición ",min:15, max:999999, color:green[400]}
    ],
    "sleep_qualities": [
        {"value": 1,"label": "Buena",min:0, max:5, color:green[400]},
        {"value": 2,"label": "Moderada",min:6, max:14, color:blue[400]},
        {"value": 3,"label": "Poca",min:15, max:999999, color:red[400]}
    ],
    "energy_levels": [
        {"value": 1,"label": "Buen nivel de energía",min:0, max:5, color:green[400]},
        {"value": 2,"label": "Moderado nivel de energía",min:6, max:14, color:blue[400]},
        {"value": 3,"label": "Bajo nivel de energía",min:15, max:999999, color:red[400]},
    ],
    "inactive_reasons":[
        {"value": 1,"color":null,"label": "Sin definir"},
    ],
    "disabled_reasons":[
        {"value": 1,"color":null,"label": "Sin definir"},
    ]

}

export const CARDS = {}