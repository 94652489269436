import React, { useEffect, useRef, useState } from 'react'
import { Card, Fade, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iconify from '../../../../components/iconify';
import LoadingContainer from '../../../../components/loaders/LoadingContainer';
import { Stack } from '@mui/system';
import TextButton from '../../../../components/actions/TextButton';
import EmptyCartPlaceholder from '../../../../components/platform/placeholders/EmptyCartPlaceholder';
import { onGetCurrencyValue } from '../../../../utils/currencies';
import GenericProduct from '../../../../components/platform/products/GenericProduct';
import Scrollbars from 'react-custom-scrollbars';
import useCart from '../../../../hooks/api/useCart';
import { useSnackbar } from 'notistack';
import logo from '../../../../assets/company/logo-wh.png'
import QRCodeStyling from "qr-code-styling";
import { CONFIG_SETTINGS } from '../../../../config';
import cx from 'classnames'
const _qrCodeEl = new QRCodeStyling({
    width:181,
    height:181,
    image:logo,
    dotsOptions:{
        type:'extra-rounded'
    }
})

const CartDetails = (props) => {

    const classes = useStyles()
    const {cart, onClose,loading, onAddProduct, onRefresh, ismodal, onAssignCart2Patient} = props
    const [selected, setSelected] = useState()
    const cartManager = useCart()
    const { enqueueSnackbar } = useSnackbar();
    const qrRef = useRef()

    useEffect(() => {
        _qrCodeEl.append(qrRef.current)
    }, [loading])

    useEffect(() => {
        const qrCodeData = `${CONFIG_SETTINGS.HOST_URL}?template_id=${CONFIG_SETTINGS.CART_QR_PREFIX}:${cart?.id_cart??''}`.replace('#','_')
        _qrCodeEl.update({
          data: qrCodeData
        });
      }, [cart]);

    const line_items = cart?.line_items ?? []

    const onChangeQuantity = async(_selected_product, _quantity, line_item) => {
        setSelected(_selected_product)
        const data2send = {
            product_id: _selected_product?.id_product || _selected_product?.product_id,
            quantity:_quantity
        }
        try {
            
            await cartManager.actions.onAddRemoveLineItem(cart,line_item,data2send)
            await onRefresh() 
            
        } catch (error) {
            console.log(error)
            enqueueSnackbar(error?.message, {variant:"error"})
        }
        setSelected(null)
    }
    return ( 
        <Fade in timeout={750}>
            <Card className={cx({
                [classes.card]:true,
                [classes.card_modal]:ismodal
            })}>
                {!ismodal && <Grid container>
                    <Grid item xs>
                        <Typography variant='h6'>
                            {`Carrito ${cart?.id_cart}`}
                        </Typography>
                        <Typography color='textSecondary'>{cart?.alias}</Typography>
                    </Grid>
                     <Grid item>
                        <IconButton onClick={onClose}><Iconify icon='eva:close-fill' sx={{
                                width:24,
                                height:24
                            }}/></IconButton>
                    </Grid>
                </Grid>}
                {loading ? <LoadingContainer /> :
                 <Fade in={!loading}>
                <div>
                    
                    <Stack>
                        <Stack direction='row' justifyContent='flex-end'>
                            <TextButton onClick={onAddProduct}>Agregar producto</TextButton>
                        </Stack>
                        {line_items.length === 0 ? (
                            <EmptyCartPlaceholder />
                        ) : (
                            <Scrollbars autoHeight autoHeightMax={550}>
                                <div style={{padding:'32px 8px', paddingRight:24}}>
                                    <Grid container spacing={2}>
                                        {line_items.map(item => {
                                            const isbusy = selected && (selected.product_id === item.product_id)

                                            return(
                                                <Grid item xs={12}  key={`product-id-${item?.id_product}`}>
                                                    <GenericProduct 
                                                        disabledPrice disabledOffer
                                                        product={item}
                                                        image_xs={4}
                                                        loading={isbusy}
                                                        quantity={item?.quantity ?? 0}
                                                        onChangeQuantity={
                                                            (p,q) => onChangeQuantity(p,q, item)
                                                        }
                                                    />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </div>
                            </Scrollbars>
                        )}
                        
                        <Stack  alignItems='center'>
                            <div ref={qrRef} /> 
                            <TextButton onClick={onAssignCart2Patient}><Iconify icon='mdi:user-arrow-right' style={{marginRight:8, textTransform:'none'}}/>Asignar a paciente</TextButton>
                            <TextButton disabled><Iconify icon='ic:outline-email' style={{marginRight:8}}/>Enviar</TextButton>
                            {/*<Typography style={{fontSize:'1.125rem', marginTop:16}}>
                                Total:  <strong>{onGetCurrencyValue(cart?.payment_subtotal)}</strong>
                                    </Typography>*/}
                        </Stack>
                    </Stack>
                </div>
                </Fade>}
            </Card>
        </Fade>
        
     );
}

const useStyles = makeStyles(theme => ({
    card:{
        maxWidth:600,
        boxSizing:'border-box',
        padding:'24px 16px',
        minHeight:500
    },
    card_modal:{
        boxShadow:'none',
        padding:'0px 0px'
    }
}))
 
export default CartDetails;