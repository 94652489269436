import { useMemo, useState, useCallback } from 'react';

// ----------------------------------------------------------------------

/**
 * @typedef {Object} UseBooleanReturn
 * @property {boolean} value - The current boolean value.
 * @property {Function} onTrue - Sets the value to true.
 * @property {Function} onFalse - Sets the value to false.
 * @property {Function} onToggle - Toggles the current value.
 * @property {Function} setValue - Sets the value explicitly.
 */

/**
 * A custom hook to manage boolean state with utility functions.
 *
 * @param {boolean} [defaultValue=false] - Initial boolean value.
 * @returns {UseBooleanReturn} - An object containing the boolean state and utility functions.
 */
export function useBoolean(defaultValue = "") {
  const [value, setValue] = useState(defaultValue);

  const onTrue = useCallback(() => {
    setValue(true);
  }, []);

  const onFalse = useCallback(() => {
    setValue(false);
  }, []);

  const onToggle = useCallback(() => {
    setValue((prev) => !prev);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      value,
      onTrue,
      onFalse,
      onToggle,
      setValue,
    }),
    [value, onTrue, onFalse, onToggle]
  );

  return memoizedValue;
}
