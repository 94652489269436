import React, { useCallback, useEffect, useState } from 'react';
import PageLayout from '../../../layouts/PageLayout';
import NavigationTab from '../../../components/actions/NavigationTab';
import Iconify from '../../../components/iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { alpha, Box, Card, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router';
import PatientsBusinessProgramSection from './PatientsBusinessProgramSection/PatientsBusinessProgramSection';
import useInitState from '../../../hooks/useInitState';
import { getClient } from '../../../services/clientService';
import { getClientProgram } from '../../../services/clientProgramService';
import { CustomAvatar } from '../../../components/custom-avatar';
import AppointmentsProgramSection from './AppointmentsProgramSection/AppointmentsProgramSection';
import AnalyticsBusinessPrograSection from './AnalyticsBusinessPrograSection/AnalyticsBusinessPrograSection';

const BusinessProgramPage = () => {

    const {idclient, idclientprogram} = useParams()
    const {
        loading
    } = useInitState()
    const [currentTab, setCurrentTab] = useState('patients')
    const [client, setClient] = useState()
    const [program, setProgram] = useState()

    const initialize = useCallback(async() => {
        console.log(idclient, idclientprogram)
        loading.onTrue()
        try {
            const _data = await getClient(idclient)
            setClient(_data)
            const _program = await getClientProgram(idclientprogram)
            setProgram(_program)
        } catch (error) {
            
        }

        loading.onFalse()

    },[idclient, idclientprogram])

    useEffect(() => {
        initialize()
    },[idclient, idclientprogram])

    const TABS = [
        {
            value: 'patients',
            label: 'Usuarios',
            icon: <Iconify icon="fa:group" />,
            component: <PatientsBusinessProgramSection idclient={idclient} idclientprogram={idclientprogram} />,
        },
        {
            value: 'appointments',
            label: 'Mapa de consultas',
            icon: <Iconify icon="streamline:waiting-appointments-calendar-solid" />,
            component: <AppointmentsProgramSection idclient={idclient} idclientprogram={idclientprogram} />,
        },
        {
            value:'analitics',
            label:'Analíticos',
            icon: <Iconify icon="simple-icons:googleanalytics" />,
            component: <AnalyticsBusinessPrograSection idclient={idclient} idclientprogram={idclientprogram} />
        }
    ]
    return ( 
        <PageLayout stretch={true}
            title='Detalles del programa'
            title_seo='Detalle del programa | Velfare'
            enable_return
            loading={loading.value}
            links={[
                {name:'Dashboard', href:PATH_DASHBOARD.general.app},
                {name:'Negocios', href:PATH_DASHBOARD.general.clients},
                {name:'Cuenta', },
            ]}
        >
            <div>
                <Card sx={{
                    borderRadius:1,
                    px:2, py:2,
                    background: theme => alpha(theme.palette.primary.main, 0.2)
                }}>
                    
                    <Grid container wrap='nowrap' spacing={1} alignItems={'center'}>
                        
                        <Grid item >
                            <CustomAvatar src={client?.image} alt={program?.program} name={program?.program} />
                        </Grid>
                        <Grid item>
                            <Typography variant='h6'>
                                Programa {program?.program}
                            </Typography>
                            <Typography variant='subtitle2' color='text.secondary'> 
                                {client?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
                <div>
                        <NavigationTab options={TABS} value={currentTab} onChange={(value) => setCurrentTab(value)}/>
                        {TABS.map(
                        (tab) =>
                            tab.value === currentTab && (
                                <Box key={tab.value} sx={{ mt: 2 }}>
                                    {tab.component}
                                </Box>
                            )
                        )}
                </div>
            </div>
        </PageLayout>
     );
}
 
export default BusinessProgramPage;