import { private_server } from "../utils/axios"

export const getClients = async(params) => {
    let result = {
        data:[], 
        count:0
    }
    const request = await private_server.get(`/client/all`,{params:params})
    result.data = request.data.data?.clients??[]
    result.count = request.data.data.count
    return result
}

export const getNutritionistClients = async(params) => {
    let result = {
        data:[], 
        count:0
    }
    const request = await private_server.get(`/nutritionistclient/all`,{params:params})
    result.data = request.data.data?.nutritionist_clients??[]
    result.count = request.data.data.count
    return result
}

export const getClient = async(idclient) => {
    let result = null
    const request = await private_server.get(`/client/${idclient}`)
    result = request.data.data.client
    return result
}



export const getClientLocations = async(idclient, params, filter=[]) => {
    let result = {
        data:[], 
        count:0
    }

    let _andArgs = [{field:'client_id', operator:"=", value:idclient}]
    if(filter.length>0) _andArgs = [..._andArgs, ...filter]
    let _params = {...params}
    _params.filter = JSON.stringify({AND:_andArgs})
     
    const request = await private_server.get(`/clientlocation/all`, {params:_params})
    result.data = request.data.data?.client_locations??[]
    result.count = request.data.data.count
    return result
}