import { private_server } from '../../utils/axios';

const useClientProduct = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/clientproduct/all', {params:params})
            result.data = request.data.data?.client_products??[]
            result.count = request.data.data.count
            return result
        },
        onGet: async(idproduct) => {
            let result = null
            const request = await private_server.get(`/product/${idproduct}`)
            result = request.data.data.product
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/clientproduct', data2send)
            result = request.data.data?.product?.id_product
            return result
        },
        onDelete: async(id) => {
            await private_server.delete(`/clientproduct/${id}`)
        },
    }

    return {
        actions
    }
}
 
export default useClientProduct;