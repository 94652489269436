import React from 'react';
import { Card, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import rxIcon from '../../../assets/platform/icons/tags/rx.svg'
import SVGIcon from '../../images/SVGIcon';
import OrderedProduct from '../products/OrderedProduct';
const ProductsContainer = (props) => {

    const {line_items} = props
    const classes = useStyles()
    const theme = useTheme()

    let require_recipe = false

    line_items.forEach(item => {
        if(item.requires_recipe === 1) require_recipe = true
    })

    return ( 
        <div>
            <Grid container>
                {line_items.map(item => {
                    return(
                        <Grid item xs={12}>
                            <OrderedProduct product={{...item}} size='small'/>
                        </Grid>
                    )
                })}
                {require_recipe 
                ? <Grid item xs={12}>
                    <div className={classes.recipe_wrapper}>
                        <Grid container justifyContent='center' alignItems='center' spacing={1} wrap='nowrap'>
                            <Grid item>
                                <SVGIcon src={rxIcon} size={24} color={theme.palette.accent.main} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.recipe_text}>Se recogerá la receta en la entrega</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    
                </Grid> 
                : null}
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    recipe_text:{
        color:theme.palette.primary.main,
        fontSize:16
    },
    recipe_wrapper:{
        padding:12
    }
}))
 
export default ProductsContainer;