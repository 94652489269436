import { Button } from "@mui/material"

const MapButton = ({latitude, longitude}) => {

    const onGo2Map = () => {
        if(!latitude) return
        if(!longitude) return
        window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank')
    }

    return(
        <Button color='primary' variant='outlined' style={{borderRadius:12, textTransform:'none'}} onClick={onGo2Map}>Ver mapa</Button>
    )
}

export default MapButton