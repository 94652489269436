import { alpha, Box, Card, Grid, Stack, Typography } from '@mui/material';
import React from 'react'
import Iconify from '../iconify';

const ReportIndexMenu = ({
    onChange
}) => {
    return ( 
        <div>
            <Grid container spacing={1}>
                {menu.map(item => {
                    return(
                        <Grid item xs={12}  key={item.label}>
                            <Card component={'div'} onClick={() => onChange(item.id)}  sx={{
                                p:2,
                                border:theme => `2px solid ${theme.palette.primary.main}`,
                                cursor:'pointer',
                                '&:hover':{
                                    background: theme => alpha(theme.palette.primary.main, 0.2)
                                }
                            }}>
                                <Grid container alignItems='center' spacing={2}>
                                    <Grid item>
                                        <Box>
                                            <Typography variant='h4' color='primary'>
                                                {item.index}
                                            </Typography>
                                            
                                        </Box>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='h5' sx={{fontWeight:500}} >
                                            {item.label}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Iconify icon="mi:arrow-right" width={24} />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
     );
}
 
export default ReportIndexMenu;

const menu = [
    {id:'overview', index:'01', label:'Resumen ejecutivo'},
    {id:'global', index:'02', label:'Datos globales'},
    {id:'region', index:'03', label:'Datos por región'},
    {id:'others', index:'04', label:'Anexo'},
]