import { private_server } from '../../utils/axios';
import { CURRENCY } from '../../utils/enums';


const useOrder = () => {   

    const actions = {
        onGetAll: async(pharmacyid, params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get(`/pharmacy/${pharmacyid}/orders`, {params:{...params}})
            result.data = request.data.data.orders
            result.count = request.data.data.count
            return result
        },
        onGetOrder: async(orderid) => {
            let result = null
            const request = await private_server.get(`/order/${orderid}`)
            console.log(request.data.data)
            result = request.data.data.order
            return result
        },
        onDeleteOrder: async(orderid) => {
            await private_server.delete(`/order/${orderid}/template`)
        },
        onAddTemplate: async(data2send) => {
            let result = null
            const request = await private_server.post('/order/template/me', {currency_id: CURRENCY.MXN, ...data2send})
            result = request.data.data.order_id
            return result
        },
        onEditOrder: async(orderid, data2send) => {
            await private_server.post(`/order/${orderid}/data/template`, data2send)
        },
        onAssignUser: async(orderid, data2send) => {
            await private_server.post(`order/me/assign/${orderid}/template`, data2send)
        },
        onAddRemoveLineItem: async(order, line_item, data2send ) => {
            const {id_order} = order
            const {quantity} = data2send
            if(!line_item){ // Line item does not exist
                await private_server.post(`/lineitem/me/${id_order}/template`, data2send)          
            }else{
                const {id_line_item} = line_item
                if(quantity > 0) await private_server.patch(`/lineitem/me/${id_order}/${id_line_item}/template`, data2send)
                if(quantity === 0) await private_server.delete(`/lineitem/me/${id_order}/${id_line_item}/template`)
            }
        },
    }

    return {
        actions
    }
}
 
export default useOrder;