import axios from 'axios';
import { CONFIG_SETTINGS } from '../config';
import { ERROR_CODES } from './errorCodes';

/////////////////////////// PUBLIC SERVER  ///////////////////////////////////////

const _public_server = axios.create({
  baseURL: CONFIG_SETTINGS.API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization':CONFIG_SETTINGS.API_BEARER
  },
})

_public_server.interceptors.response.use(
  (response) => response,
  (error) => {
    let error_data = {
      code:0,
      message:'Hubo un error'
    }
    if(error?.response && error?.response?.data && error?.response?.data?.error){
      const error_translation = ERROR_CODES[error?.response?.data?.error?.code]
      error_data = error?.response?.data?.error
      error_data.message = Boolean(error_translation) ? error_translation : error?.response?.data?.error?.message??'Hubo un error'
    }
    //return Promise.reject((error?.response && error?.response?.data) || 'Something went wrong')
    return Promise.reject(error_data)
  }
);

export const public_server = _public_server

/////////////////////////// PRIVATE SERVER  ///////////////////////////////////////

const _private_server = axios.create({
  baseURL: CONFIG_SETTINGS.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

_private_server.interceptors.response.use(
  (response) => response,
  (error) => {
    let error_data = {
      code:0,
      message:'Hubo un error'
    }
    if(error?.response && error?.response?.data && error?.response?.data?.error){
      const error_translation = ERROR_CODES[error?.response?.data?.error?.code]
      error_data = error?.response?.data?.error
      error_data.message = Boolean(error_translation) ? error_translation : error?.response?.data?.error?.message??'Hubo un error'
    }
    //return Promise.reject((error?.response && error?.response?.data) || 'Something went wrong')
    return Promise.reject(error_data)
  }
);

export const private_server = _private_server


/////////////////////////// PRIVATE SERVER  ///////////////////////////////////////

const _generic_server = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
})

_generic_server.interceptors.response.use(
  (response) => response,
  (error) => {
    let error_data = {
      code:0,
      message:'Hubo un error'
    }
    if(error?.response && error?.response?.data){
      error_data = error?.response?.data
    }
    //return Promise.reject((error?.response && error?.response?.data) || 'Something went wrong')
    return Promise.reject(error_data)
  }
);

export const generic_server = _generic_server
