import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import AnalyticsHeader from '../../../../components/analytics/AnalyticsHeader';
import ReportIndexMenu from '../../../../components/analytics/ReportIndexMenu';
import ExecutiveReport from '../../../../components/analytics/ExecutiveReport';
import GlobalReport from '../../../../components/analytics/GlobalReport';
import RegionReport from '../../../../components/analytics/RegionReport';
import AnexoReport from '../../../../components/analytics/AnexoReport';


const AnalyticsBusinessPrograSection = ({
    idclient, 
    idclientprogram
}) => {

    const [period, setPeriod] = useState({
        month:moment().month(),
        year:moment().year()
    })

    const [view, setView] = useState(null)

    let contentView = null
    if(!view){
        contentView = <ReportIndexMenu onChange={(value) => setView(value)}/>
    }else{
        if(view === 'overview') contentView = <ExecutiveReport 
            idclient={idclient} 
            idclientprogram={idclientprogram} 
            period={period}
            onReturn={() => setView(null)}
        />
        if(view === 'global') contentView = <GlobalReport 
            idclient={idclient} 
            idclientprogram={idclientprogram} 
            period={period}
            onReturn={() => setView(null)}
        />
        if(view === 'region') contentView = <RegionReport 
            idclient={idclient} 
            idclientprogram={idclientprogram} 
            period={period}
            onReturn={() => setView(null)}
        />
        if(view === 'others') contentView = <AnexoReport 
            idclient={idclient} 
            idclientprogram={idclientprogram} 
            period={period}
            onReturn={() => setView(null)}
        />
    }
   

    return ( 
        <Box>
            <AnalyticsHeader 
                title={'Reporte'}
                period={period}
                onChange={(data) => setPeriod(data)}
            />
            <Box>
                {contentView}
            </Box>
        </Box>
     );
}
 
export default AnalyticsBusinessPrograSection;