import { Card, Fade, Grid, IconButton, Typography } from '@mui/material';
import React from 'react'
import Iconify from '../../iconify';
import { makeStyles } from '@mui/styles';
import cx from 'classnames'
import LoadingContainer from '../../loaders/LoadingContainer';
import GenericAccordion from '../GenericAccordion';
import ProductsContainer from './ProductsContainer';
import DeliveryContainer from './DeliveryContainer';
import PaymentDetails from './PaymentDetails';
import PaymentType from './PaymentType';
import { Stack } from '@mui/system';
import { onGetDateWithTimezone } from '../../../utils/formatTime';
import StatusChipBtn from './StatusChipBtn';
import { catalogs } from '../../../utils/catalogs';

const OrderDetailCard = (props) => {

    const classes = useStyles()
    const {order, onClose,loading, ismodal} = props

    const line_items = order?.cart?.line_items ?? []

    const date = onGetDateWithTimezone(order?.order_time,'DD MMM YY, HH:mm')
    const order_status = catalogs.order_statuses.find(el => el.value === order?.order_status_id)
    const delivery_status = catalogs.order_statuses.find(el => el.value === order?.route_order_status_id)

    return ( 
        <Fade in timeout={750}>
            <Card className={cx({
                [classes.card]:true,
                [classes.card_modal]:ismodal
            })}>
                {!ismodal && <Grid container>
                    <Grid item xs>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Stack>
                                    <Typography variant='h6'>
                                        {`Orden #${order?.id_order}`}
                                    </Typography>
                                    <Typography variant='caption' color='textSecondary'>
                                        Fecha de solicitud <br/> {` ${date ?? 'No disponible'}`}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <StatusChipBtn status={order_status} />
                            </Grid>
                            <Grid item>
                                <StatusChipBtn status={delivery_status}/>
                            </Grid>
                            
                            
                           
                        </Grid>
                        

                    </Grid>
                     <Grid item>
                        <IconButton onClick={onClose}><Iconify icon='eva:close-fill' sx={{
                                width:24,
                                height:24
                            }}/></IconButton>
                    </Grid>
                </Grid>}
                {loading ? <LoadingContainer /> :
                 <Fade in={!loading}>
                    <div>
                        <GenericAccordion title='Resumen de productos' defaultExpanded>
                            <ProductsContainer line_items={line_items}/>
                        </GenericAccordion >
                        <GenericAccordion title='Entrega' defaultExpanded>
                            <DeliveryContainer order={order} />
                        </GenericAccordion >
                        <GenericAccordion title='Detalle de cobro' defaultExpanded>
                            <PaymentDetails order={order} />
                        </GenericAccordion>
                        <GenericAccordion title='Método de pago' defaultExpanded>
                            <PaymentType order={order} />
                        </GenericAccordion>
                    </div>
                </Fade>}
            </Card>
        </Fade>
    );
}

const useStyles = makeStyles(theme => ({
    card:{
        maxWidth:'100%',
        boxSizing:'border-box',
        padding:'24px 16px',
        minHeight:600
    },
    card_modal:{
        boxShadow:'none',
        padding:'0px 0px'
    }
}))
 
export default OrderDetailCard;