import React, { useCallback, useEffect, useState } from 'react';
import { Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import CircularLoadingContainer from '../../../../components/loaders/CircularLoadingContainer';
import useAnalytics from '../../../../hooks/api/useAnalytics';

const GlobalPlatformCount = ({
    start_date, end_date, client_id, client_location_id, client_program_id
}) => {

    const [loading, setLoading] = useState(false)
    const [analytics, setAnalytics] = useState({})
    const analyticsManager = useAnalytics()

    const initialize = useCallback(async() => {
        if(loading) return
        setLoading(true)
        try {
            const params = {start_date: start_date, end_date:end_date, 
                client_id: client_id > 0 ? client_id : undefined,
                client_location_id: client_location_id > 0 ? client_location_id : undefined,
                client_program_id: client_program_id > 0 ? client_program_id : undefined,
            }
            let _analytics = {}
            const _patient_analytics = await analyticsManager.actions.onGetGlobalPatientCount(params)
            _analytics = {..._analytics, ..._patient_analytics}
            const _appointment_analytics = await analyticsManager.actions.onGetGlobalAppointmentCount(params)
            _analytics = {..._analytics, ..._appointment_analytics}
            setAnalytics(_analytics)
        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }, [loading, start_date, end_date, client_id, client_location_id, client_program_id])

    useEffect(() => {
        initialize()
    }, [start_date, end_date, client_id, client_location_id, client_program_id])


    return ( 
        <Card>
            <CircularLoadingContainer loading={loading} />
            <Table>
                <TableHead>
                    <TableRow sx={{
                        background: (theme) => theme.palette.primary.main
                    }} >
                        <TableCell colSpan={2} sx={{background:'transparent', px:1, py:1}}>
                            <Typography variant='subtitle2' color='white'>Conteo Global</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <AnalyticDataElement 
                        label='Inscritos'
                        data={analytics?.registered_users ?? 0}
                    />
                    <AnalyticDataElement 
                        label='Con consulta inicial'
                        data={analytics?.initial_appointment_users ?? 0}
                    />
                    <AnalyticDataElement 
                        label='Con consulta agendada'
                        data={analytics?.scheduled_appointment_users ?? 0}
                    />
                    <AnalyticDataElement 
                        label='Pendientes'
                        data={analytics?.pending_users ?? 0}
                    />
                    <AnalyticDataElement
                        label='Bajas'
                        data={analytics?.disabled_users ?? 0}
                    />
                    <AnalyticDataElement 
                        label='Consultas'
                        data={analytics?.valid_appointments ?? 0}
                    />
                    <AnalyticDataElement noline
                        label='Consultas por paciente'
                        data={analytics?.appointments_per_user ?? 0}
                    />
                </TableBody>
            </Table>
        </Card>
     );
}
 
export default GlobalPlatformCount;

const AnalyticDataElement = ({
    label, 
    data=0,
    noline
}) => {
    return (
        <TableRow>
            <TableCell sx={{py:2, borderBottom:`1px solid ${noline ? 'transparent' : grey[200]}`}}>
                <Typography variant='body2' color='textSecondary'>{label}</Typography>
            </TableCell>
            <TableCell sx={{py:2, borderBottom:`1px solid ${noline ? 'transparent' : grey[200]}`}} >
                <Typography variant='subtitle2'>{data}</Typography>
            </TableCell>
        </TableRow>
    )
}