import { private_server } from '../../utils/axios';

const useCategory = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/category/all', {params:params})
            result.data = request.data.data?.categories??[]
            result.count = request.data.data.count
            return result
        },
        onGet: async(idcategory) => {
            let result = null
            const request = await private_server.get(`/category/${idcategory}`)
            result = request.data.data.category
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/category', data2send)
            result = request.data.data?.category?.id_category
            return result
        },
        onEdit: async(idcategory, data2send) => {
            await private_server.patch(`/category/${idcategory}`, data2send)
        },
        onDelete: async(id) => {
            await private_server.delete(`/category/${id}`)
        },
    }

    return {
        actions
    }
}
 
export default useCategory;