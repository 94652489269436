import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Iconify from '../../iconify';

//const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
//const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleCheckboxAutocomplete({
    id,
    label,
    placeholder,
    options=[],
    value=[],
    onChange
}) {


    const onInnerChange = (e,d) => {
        //console.log(e,d)
        onChange(d)
    }

    return (
        <Autocomplete
            multiple
            value={value}
            defaultValue={value}
            id={id}
            options={options}
            disableCloseOnSelect
            onChange={onInnerChange}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                <Checkbox
                    icon={<Iconify icon='mdi:checkbox-blank-outline'/>}
                    checkedIcon={<Iconify icon='mdi:checkbox-marked'/>}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.label}
                </li>
            )}
            fullWidth
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder} />
            )}
        />
    );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
