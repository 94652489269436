import React, { useState, useEffect } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import Chart, { useChart } from '../../chart';
import { catalogs } from '../../../utils/catalogs';
import { grey } from '@mui/material/colors';

const GlobalRiskGroupChart = ({
    data
}) => {

    const [series, setSeries] = useState([])
    const [labels, setLabels] = useState([])
    const [colors, setColors] = useState([])
    console.log(series)

    useEffect(() =>{
        console.log(data)
        let _result = []
        let _labels =[]
        let _colors =[]
        for (let index = 0; index < catalogs.risk_levels.length; index++) {
            const element = catalogs.risk_levels[index];
            const foundData = data.find(el => el.risk_level_id === element?.value)
            console.log(foundData)
            _result.push(Number(foundData?.user_count))
            _labels.push(element?.label)
            _colors.push(element?.color)

            
        }
        setSeries(_result)
        setLabels(_labels)
        setColors(_colors)

    },[data])

    //let series = [12, 30, 22] 
    

    let _options = [
        ...catalogs.risk_levels,
        //{value:-1, label:'Sin clasificar', color:grey[300]}
    ]

    const chartOptions = useChart({
        labels: _options.map((el) => el.label),
        legend: {
            position:'bottom'
        },
        colors:_options.map((el) => el.color)
    })

    return ( 
        <Box>
            <Typography variant='h5' gutterBottom>
                Clasificación de grupo de riesgo
            </Typography>
            <Box sx={{p:2}}>
                <Box>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    width:'100%',
                                    maxWidth:500,
                                    margin:'auto'
                                }}
                                
                            >
                                <Chart
                                    type="pie" 
                                    series={series} 
                                    options={chartOptions}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{p:5}}>
                            <img src="/assets/images/analytics/riskgrouptable.png" alt="grupo de riesgo tabla" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
     );
}
 
export default GlobalRiskGroupChart;

/*[
    "3",
    "0",
    "1",
    0
]*/