import { Box, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import Iconify from '../iconify';
import number2Digits from '../../utils/general';
import moment from 'moment';
import { getAnalyticsPathologiesMetrics, getAnalyticsRiskGroupMetrics,  getGlobalPatientCount,  } from '../../services/analyticsService';
import LoadingContainer from '../loaders/LoadingContainer';
import useInitState from '../../hooks/useInitState';
import GlobalPyramidChart from './global/GlobalPyramidChart';
import GlobalRiskGroupChart from './global/GlobalRiskGroupChart';
import GlobalPathologiesChart from './global/GlobalPathologiesChart';
import GlobalAnthropometricChart from './global/GlobalAnthropometricChart';
import GlobalAnthropometricPieChart from './global/GlobalAnthropometricPieChart';
import GlobalIccPieChart from './global/GlobalIccPieChart';

const GlobalReport = ({
    idclient, 
    idclientprogram, 
    period,
    onReturn,
    
}) => {

    const {
        loading
    } = useInitState()

    const [global_patient, setGlobalPatient] = useState()
    const [pathologies, setPathologies] = useState()
    const [risk_group, setRiskGroup] = useState([])

    const initialize = useCallback(async() => {
        const month = number2Digits((period?.month ?? 0)+1)
        const year = period?.year
        const startdate = `${year}-${month}-01` 
        const enddate = moment(startdate).endOf('month').format('YYYY-MM-DD')

        

        loading.onTrue()
        try {

            const _patientdata = await getGlobalPatientCount({
                start_date:startdate,
                end_date:enddate,
                client_id:idclient,
                client_program_id:idclientprogram
            })
            //console.log(_patientdata)
            setGlobalPatient(_patientdata)
            
        } catch (error) {
            console.log(error)
        }

        try {

            const _riskgroupdata = await getAnalyticsRiskGroupMetrics({
                start_date:startdate,
                end_date:enddate,
                client_program_id:idclientprogram
            })
            console.log(_riskgroupdata)
            setRiskGroup(_riskgroupdata)
            //setGlobalPatient(_patientdata)
            
        } catch (error) {
            //console.log(error)
        }

        
        try {

            const _pathologiespdata = await getAnalyticsPathologiesMetrics({
                start_date:startdate,
                end_date:enddate,
                client_id:idclient,
                client_program_id:idclientprogram
            })
            //console.log(_pathologiespdata)
            setPathologies(_pathologiespdata)
            //setGlobalPatient(_patientdata)
            
        } catch (error) {
            console.log(error)
        }
       
        loading.onFalse()

    }, [period, idclient, idclientprogram])

    useEffect(() => {
        initialize()
    }, [])


    return ( 
        <Box>
            <Stack direction='row'>
                <IconButton onClick={onReturn}>
                    <Iconify icon="material-symbols:arrow-back-rounded" />
                </IconButton>
                <Typography variant='h5'>
                    Datos Globales
                </Typography>
                
            </Stack>
            <Box sx={{py:3}}>
                <LoadingContainer loading={loading.value}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={7}>
                                        <GlobalPyramidChart 
                                            total_users={global_patient?.total_users}
                                            initial_appointment_users={global_patient?.initial_appointment_users}
                                            follow_up_appointment_users={global_patient?.follow_up_appointment_users}
                                            scheduled_appointment_users={global_patient?.scheduled_appointment_users}
                                            pending_users={global_patient?.pending_users}
                                            disabled_users={global_patient?.disabled_users}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalRiskGroupChart 
                                    data={risk_group}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalPathologiesChart 
                                    {...pathologies}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalAnthropometricChart 
                                    idclient={idclient}
                                    idclientprogram={idclientprogram}
                                    period={period}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalAnthropometricPieChart 
                                    idclient={idclient}
                                    idclientprogram={idclientprogram}
                                    period={period}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <GlobalIccPieChart 
                                    idclient={idclient}
                                    idclientprogram={idclientprogram}
                                    period={period}
                                />
                            </Grid>
                        </Grid>
                    </LoadingContainer>
            </Box>
            
        </Box>
     );
}
 
export default GlobalReport;