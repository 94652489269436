import React from 'react'
import { Card, Grid, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { onGetCurrencyValue } from '../../../utils/currencies';
import cx from 'classnames'
import { green } from '@mui/material/colors';

const OrderCard = ({
    order,
    selected,
    onSelect
}) => {

    const classes = useStyles()

    return ( 
        <Card className={cx({
            [classes.card]:true,
            [classes.card_selected]:selected
        })} onClick={onSelect}>
            <Grid container alignItems='center'>
                <Grid item xs={8}>
                    <Stack>
                        <Typography variant='h6'>
                            Order #{order?.id_order}
                        </Typography>
                        <Stack>
                            <Typography variant='caption' color={selected ? 'white' : 'textSecondary'}>Paciente</Typography>
                            <Typography variant='body2'>{order?.user}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Typography variant='h6' align='center'>
                            {onGetCurrencyValue(order?.payment_total ?? "0.00")}
                        </Typography>  
                        <Typography variant='body2' align='center' color={selected ? 'white' : green[700]}>
                            +{onGetCurrencyValue(order?.comission_total ?? "0.00")}
                        </Typography>  
                    </Stack>
                    
                </Grid>
            </Grid>
            <Stack justifyContent='flex-end' direction='row'>
            </Stack>
        </Card>
     );
}

const useStyles = makeStyles(theme => ({
    card:{
        padding:12,
        cursor:'pointer',
        transition:'0.3s all linear',
        '&:hover':{
            background:alpha(theme.palette.primary.light,0.3),
            transition:'0.3s all linear',
        }
    },
    card_selected:{
        background:theme.palette.primary.main, color:'white',
        '&:hover':{
            background:theme.palette.primary.main
        }
    }
}))
 
export default OrderCard;