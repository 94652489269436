import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import GlobalPlatformCount from '../async_components/GlobalPlatformCount';
import AppointmentMotiveChart from '../async_components/AppointmentMotiveChart';
import GroupRiskChart from '../async_components/GroupRiskChart';
import PathologiesChart from '../async_components/PathologiesChart';
import IMCChart from '../async_components/IMCChart';
import ICCChart from '../async_components/ICCChart';
import DigestionChart from '../async_components/DigestionChart';
import PhysicalConditionChart from '../async_components/PhysicalConditionChart';
import SleepQualityChart from '../async_components/SleepQualityChart';
import EnergyLevelChart from '../async_components/EnergyLevelChart';
import moment from 'moment';
import CustomSmallSelect from '../../../../components/custom-input/CustomSmallSelect';
import useClient from '../../../../hooks/api/useClient';
import { onGetCatalogs } from '../../../../utils/forms';
import useClientLocation from '../../../../hooks/api/useClientLocation';

const dateRangeOptions = [
    {id:1, label:'2023', start_date:'2023-01-01', end_date:'2023-12-31' },
    {id:2, label:'Último mes', start_date:moment().subtract(1, 'month').format('YYYY-MM-DD'), end_date:moment().format('YYYY-MM-DD') },
    {id:3, label:'Últimos 3 meses', start_date:moment().subtract(3, 'month').format('YYYY-MM-DD'), end_date:moment().format('YYYY-MM-DD') },
    {id:4, label:'Últimos 6 meses', start_date:moment().subtract(6, 'month').format('YYYY-MM-DD'), end_date:moment().format('YYYY-MM-DD') },
]

const GeneralAnalyticsSection = () => {

    const clientManager = useClient()
    const clientlocationManager = useClientLocation()

    const [selected, setSelected] = useState(1)
    const [start_date, setStartDate] = useState('2023-01-01')
    const [end_date, setEndDate] = useState('2023-12-31')
    const [business, setBusiness] = useState()
    const [businesses, setBusinesses] = useState([])
    const [location, setLocation] = useState()
    const [locations, setLocations] = useState([])

    const onGetBusiness = useCallback(async() => {
        try {
            const _clients = await clientManager.actions.onGetAll({order:0, order_by:JSON.stringify(['name'])})
            const _tempclients = onGetCatalogs(_clients.data, 'id_client', 'name')
            setBusiness(-1)
            setBusinesses([{value:-1, label:'Todos'}, ..._tempclients])
        } catch (error) {
            console.log(error)
        }
    }, [])

    const onGetLocations = useCallback(async() => {
        try {
            const _clientslocation = await clientlocationManager.actions.onGetAll({order:0, order_by:JSON.stringify(['name'])})
            const _tempclients = onGetCatalogs(_clientslocation.data, 'id_client_location', 'name')
            setLocation(-1)
            setLocations([{value:-1, label:'Todos'}, ..._tempclients])
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        onGetBusiness()
        onGetLocations()
    }, [])


    const onUpdateDates = (e) => {
        const value =  parseInt(e.target.value)
        setSelected(setSelected)
        const findObj = dateRangeOptions.find(el => el.id === value)
        setStartDate(findObj?.start_date)
        setEndDate(findObj?.end_date)
    }

    const onUpdateBusiness = (e) => {
        const value =  parseInt(e.target.value)
        setBusiness(value)
        setLocation(-1)
    }

    const onUpdateBusinessLocation = (e) => {
        const value =  parseInt(e.target.value)
        setLocation(value)
    }

    return ( 
        <Box>   
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Typography variant='subtitle1'>Datos generales</Typography>
                        </Grid>
                        <Grid item>
                            <CustomSmallSelect value={business} onChange={onUpdateBusiness}>
                                {businesses.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </CustomSmallSelect>
                        </Grid>
                        {business > 0 && <Grid item>
                            <CustomSmallSelect value={location} onChange={onUpdateBusinessLocation}>
                                {locations.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </CustomSmallSelect>
                        </Grid>}
                        <Grid item>
                            <CustomSmallSelect value={selected} onChange={onUpdateDates}>
                                {dateRangeOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.label}
                                    </option>
                                ))}
                            </CustomSmallSelect>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <GlobalPlatformCount client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AppointmentMotiveChart client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <GroupRiskChart client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                </Grid>
                {/*<Grid item xs={12} md={6}>
                    <PathologiesChart client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <IMCChart client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ICCChart client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DigestionChart client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PhysicalConditionChart client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SleepQualityChart client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EnergyLevelChart client_id={business}  client_location_id={location} start_date={start_date} end_date={end_date} />
                                </Grid>*/}
            </Grid>
        </Box>
     );
}
 
export default GeneralAnalyticsSection;