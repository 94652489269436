import React from 'react';
import PageLayout from '../../../layouts/PageLayout';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { onGetFullImagePath } from '../../../utils/general';
import ProductInformationSection from './sections/ProductInformationSection';
import useEquivalentPage from './useEquivalentPage';

const EquivalentPage = (props) => {
    
    const {system, data, actions, form} = useEquivalentPage()

    const {file, account, locations, programs, equivalents, equivalents_smae,
        tags, product_tags,
        categories, product_categories
    } = data

    const profileImg =  onGetFullImagePath(account?.image)

    //console.log(categories, tags)

    return ( 
        <PageLayout stretch={true}
            title='Detalle del equivalente simplificado'
            title_seo='Detalle del equivalente simplificado | Velfare'
            loading={system.loading}
            links={[
                {name:'Dashboard', href:PATH_DASHBOARD.general.app},
                {name:'Equivalentes', href:PATH_DASHBOARD.templates.equivalents},
                {name:'Detalle', },
            ]}
        >
            <div>
                <ProductInformationSection 
                    file={file || profileImg} form={form} loading={system.sending} 
                    equivalents={equivalents} equivalents_smae={equivalents_smae}
                    categories={categories} product_categories={product_categories}
                    tags={tags} product_tags={product_tags}
                    onAvatarDrop={actions.onAvatarDrop}
                    onInputChange={actions.onInputChange}
                    onSubmit={actions.onSubmit}
                    onEquivalentChange={actions.onUpdateEquivalents}
                    onEquivalentSMAEChange={actions.onUpdateEquivalentsSMAE}
                    onChangeProductCategories={actions.onChangeProductCategories}
                    onChangeProductTags={actions.onChangeProductTags}

                />
            </div>
        </PageLayout>
     );
}
 
export default EquivalentPage;