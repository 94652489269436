import { private_server } from '../../utils/axios';
import { CURRENCY } from '../../utils/enums';


const useCart = () => {   

    const actions = {
        onGetAllTemplates: async(pharmacyid, params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get(`/pharmacy/${pharmacyid}/templates`, {params:{...params}})
            result.data = request.data.data.templates
            result.count = request.data.data.count
            return result
        },
        onGetCart: async(cartid) => {
            let result = null
            const request = await private_server.get(`/cart/${cartid}`)
            result = request.data.data.cart_data
            return result
        },
        onDeleteCart: async(cartid) => {
            await private_server.delete(`/cart/${cartid}/template`)
        },
        onAddTemplate: async(data2send) => {
            let result = null
            const request = await private_server.post('/cart/template/me', {currency_id: CURRENCY.MXN, ...data2send})
            result = request.data.data.cart_id
            return result
        },
        onEditCart: async(cartid, data2send) => {
            await private_server.post(`/cart/${cartid}/data/template`, data2send)
        },
        onAssignUser: async(userid, data2send) => {
            await private_server.post(`cart/me/assign/${userid}/template`, data2send)
        },
        onAddRemoveLineItem: async(cart, line_item, data2send ) => {
            const {id_cart} = cart
            const {quantity} = data2send
            if(!line_item){ // Line item does not exist
                await private_server.post(`/lineitem/me/${id_cart}/template`, data2send)          
            }else{
                const {id_line_item} = line_item
                if(quantity > 0) await private_server.patch(`/lineitem/me/${id_cart}/${id_line_item}/template`, data2send)
                if(quantity === 0) await private_server.delete(`/lineitem/me/${id_cart}/${id_line_item}/template`)
            }
        },
    }

    return {
        actions
    }
}
 
export default useCart;