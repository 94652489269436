import React from 'react';
import logo from '../../assets/company/logo.png'
import {Stack} from '@mui/material'


const Logo = ({
    size,
}) => {
    return ( 
        <div>
            <Stack direction='row' spacing={1} alignItems='center'>
                <img src={logo} width={size} />
            </Stack>
            
        </div>
     );
}
 
export default Logo;