import React from 'react';
import {  Card, Divider, Grid, Stack, Typography, Button } from '@mui/material';
import UploadAvatarForm from '../../../../components/forms/UploadAvatarForm';
import { fData } from '../../../../utils/formatNumber';
import InputForm from '../../../../components/forms/InputForm';
import ContainedButton from '../../../../components/actions/ContainedButton';
import SwitchForm from '../../../../components/forms/SwitchForm';
import { Box } from '@mui/system';
import EquivalentForm from '../../../../components/platform/products/EquivalentsForm';
import EquivalentsSMAEForm from '../../../../components/platform/products/EquivalentsSMAEForm';
import MultipleCheckboxAutocomplete from '../../../../components/platform/form/MultipleCheckboxAutocomplete';

const ProductInformationSection = ({
    onAvatarDrop, file, loading, 
    form,
    equivalents,
    equivalents_smae,
    onSubmit, 
    onInputChange,
    onEquivalentChange,
    onEquivalentSMAEChange,
    categories,
    product_categories,
    onChangeProductCategories,
    tags, product_tags,
    onChangeProductTags
}) => {

    const active_equivalent =  form.equivalents_active.value === 1
    const active_smae =  form.smae_active.value === 1

    return ( 
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
                        
                        <UploadAvatarForm
                            name="photoURL"
                            maxSize={3145728}
                            file={file}
                            onDrop={onAvatarDrop}
                            helperText={
                                <Typography
                                variant="caption"
                                sx={{
                                    mt: 2,
                                    mx: 'auto',
                                    display: 'block',
                                    textAlign: 'center',
                                    color: 'text.secondary',
                                }}
                                >
                                Archivos permitidos *.jpeg, *.jpg, *.png, *.gif
                                <br /> Tamaño máx. {fData(3145728)}
                                </Typography>
                            }
                        /> 
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card sx={{ p: 3 }}>
                                <>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant='subtitle2' sx={{paddingBottom:3}}>Información principal</Typography>
                                        </Grid>
                                        {/*<Grid item>
                                            <SwitchForm data={form.active} onChange={onInputChange} />
                                        </Grid>*/}
                                    </Grid>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <InputForm data={form.name} onChange={onInputChange} />
                                        </Grid>
                                        {/*<Grid item xs={12}>
                                            <InputForm data={form.ingredients} onChange={onInputChange} />
                                    </Grid>*/}
                                        <Grid item xs={12}>
                                            <InputForm data={form.portion} onChange={onInputChange} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MultipleCheckboxAutocomplete
                                                id='product-cats-input'
                                                label='Categorías'
                                                options={categories}
                                                value={product_categories}
                                                onChange={onChangeProductCategories}
                                                />
                                        </Grid>
                                        {/*<Grid item xs={12}>
                                            <MultipleCheckboxAutocomplete 
                                                id='product-tags-input'
                                                label='Etiquetas'
                                                options={tags}
                                                value={product_tags}
                                                onChange={onChangeProductTags}
                                                />
                                    </Grid>*/}
                                    </Grid>
                                    <Divider sx={{mt:3}} />
                                    <Box sx={{pt:3}}>
                                        <Typography variant='subtitle2' >Equivalentes</Typography>
                                        <Grid container spacing={3}>
                                            {/*<Grid item xs={12} md={6}>
                                                <SwitchForm data={form.equivalents_active} onChange={onInputChange} />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SwitchForm data={form.smae_active} onChange={onInputChange} />    
                                            </Grid>*/}
                                            <Grid item xs={12}>
                                                <EquivalentForm 
                                                    equivalents={equivalents} 
                                                    onChange={onEquivalentChange}/>
                                            </Grid>
                                            {/*active_smae && <Grid item xs={12}>
                                                <EquivalentsSMAEForm
                                                    equivalents={equivalents_smae} 
                                                    onChange={onEquivalentSMAEChange}/>
                                        </Grid>*/}
                                        </Grid>
                                    </Box>
                                    {/*<Divider sx={{mt:3,mb:3}} />

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <InputForm data={form.available_in} onChange={onInputChange} />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <InputForm data={form.url} onChange={onInputChange} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button fullWidth variant='contained' sx={{height:'100%'}} href={form.url.value} target='_blank'>TEST</Button>
                                        </Grid>
                                        
                                    </Grid>*/}
                                </>
                                
                                <Stack alignItems='flex-end' sx={{pt:3}}>
                                    
                                    <ContainedButton loading={loading} onClick={onSubmit}>
                                        Guardar
                                    </ContainedButton>
                                </Stack>
                            </Card>
                        </Grid>
                        {/*<Grid item xs={12}>
                            <LocationsCard locations={locations}  />
                        </Grid>

                        <Grid item xs={12}>
                            <ProgramCard programs={programs}  />
                                    </Grid>*/}
                    </Grid>
                    
                </Grid>
            </Grid>
        </div>
     );
}
 
export default ProductInformationSection;