import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useEffect, useState } from "react";

const ExecutiveObjectivesCard = ({
    objectives=[]
}) => {

    const [total, setTotal] = useState(1)

    useEffect(() => {
        let result = 0
        objectives.forEach(element => {
            result = result + (parseInt(element?.user_count) ?? 0)
        });
        setTotal(result === 0 ? 1 : result)
    }, [objectives])
    
    return ( 
        <Box>
            <Typography variant='h6' gutterBottom>Información por sitios</Typography>
            <Card sx={{p:2, border:`1px solid ${blueGrey[300]}`}}>
                <Stack spacing={0.5}>
                    {objectives.map(item => {
                        return(
                            <DataEntry 
                                key={item?.objective}
                                text={item?.objective}
                                value={item?.user_count}
                                percentage={(parseFloat(item?.user_count/total)*100).toFixed(2)}
                            />
                        )
                    })}
                </Stack>
                
                
            </Card>
        </Box>
     );
}
 
export default ExecutiveObjectivesCard;

const DataEntry = ({
    text,
    value,
    percentage
}) => {
    return(
        <Box>
            <Grid container spacing={1} alignItems='center'>
                <Grid item xs={12} md={4}>
                    <Box sx={{
                        background: theme => theme.palette.primary.main,
                        color:'white',
                        py:1, px:1,
                        borderRadius:1
                    }}>
                        <Typography >
                            {text}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Stack direction='row' alignItems='flex-end' spacing={1}>
                        <Typography variant="subtitle1">
                            {percentage}%
                        </Typography>
                        <Typography  sx={{
                        }}>
                            ({value} colaboradores)
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}