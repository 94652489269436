export const ERROR_CODES = {
	"101": "La petición no tiene el cabezal de autorización",
	"102": "Token expirado",
	"103": "Token inválido",
	"104": "Crendencial inválidas",
	"105": "Usuario no autorizado para ejecutar esta acción",
	"106": "Contraseña incorrecta",
	"107": "Error en autorización",
	"108": "Usuario deshabilitado",
	"201": "La petición tiene información incorrecta",
	"301": "Error en la base de datos",
	"302": "Error desencriptando la contraseña",
	"303": "Error encriptando la contraseña",
	"304": "Error al subir la imagen",
	"305": "Error al subir el archivo",
	"306": "Error al pedir archivos",
	"307": "Error al borrar el archivo",
	"308": "Error en la descarga del archivo",
	"309": "Archivo faltante",
	"310": "Error en el servicio de mailing",
	"311": "Archivo no encontrado",
	"312": "Error en el servicio de factura",
	"401": "Usuario no encontrado",
	"402": "El correo electrónico ya está en uso",
	"403": "Género no encontrado",
	"404": "Código de celular no encontrado",
	"405": "Código de teléfono no encontrado",
	"406": "Código de teléfono de oficina no encontrado",
	"407": "Tipo de usuario no encontrado",
	"408": "Estatus de usuario no encontrado",
	"409": "Cliente no encontrado",
	"410": "Sitio del cliente no encontrada",
	"411": "Programa del cliente no encontrado",
	"412": "País no encontrado",
	"413": "Oficina no encontrada",
	"414": "Nutriólogo no encontrado",
	"415": "Información del paciente no encontrada",
	"416": "Dieta actual no encontrada",
	"417": "Nivel de Actividad Física no encontrada",
	"418": "No pertence",
	"419": "Este cliente ya existe",
	"420": "Estatus del cliente no encontrado",
	"421": "El código ya existe",
	"422": "La oficina ya exista",
	"423": "Estatus de oficina no encontrada",
	"424": "Horario del nutriólogo no encontrado",
	"425": "Horario especial del nutriólogo no encontrado",
	"426": "Consulta no encontrada",
	"427": "Estatus de la consulta no encontrada",
	"428": "Objectivo no encontrado",
	"429": "Fórmula activa no encontrada",
	"430": "Dieta no encontrada",
	"431": "Categoría no encontrada",
	"432": "Una categoría del mismo tipo ya existe",
	"433": "Tipo de categoría no encontrada",
	"434": "Tag no encontrado",
	"435": "El tag de mismo tipo ya existe",
	"436": "Tipo de tag no encontrado",
	"437": "Producto no encontrado",
	"438": "El producto ya existe",
	"439": "Categoría del producto no encontrada",
	"440": "Tag del producto no encontrado",
	"441": "Imagen del producto no encontrado",
	"442": "Plan de alimentación no encontrado",
	"443": "Plan de alimentación ya existe",
	"444": "Dificultad del plan de alimentación ",
	"445": "Categoría del plan de alimentación no encontrada",
	"446": "Tag del plan de alimentación no encontrado",
	"447": "Imagen del plan de alimentación no encontrado",
	"448": "Logro no encontrado",
	"449": "El logro ya existe",
	"450": "Tipo de logro no encontrado",
	"451": "El logro del paciente ya existe",
	"452": "Template del pland e alimentación ya existe",
	"453": "Tipo de dieta no encontrada",
	"454": "Bloque de comida no encontrado",
	"455": "Usuario no está asignado al nutriólogo",
	"456": "Usuario no asignado al cliente",
	"457": "Administradores ni nutriólogos pueden pertenecer a un cliente",
	"458": "Clientes y usuarios deben pertenecer a un cliente",
	"459": "nutriólogo seleccionado debe ser un nutriólogo",
	"460": "El usuario no es un nutriólogo",
	"461": "Usuario deshabilitado",
	"462": "Usuario pendiente de confirmación",
	"463": "Usuario no está en estatus de Invitado",
	"464": "Usuario no está en estatus de registrado",
	"465": "Usuario no está en estatus de pendiente de confirmación",
	"466": "Usuario no está en estatus de activo",
	"467": "Usuario no está en estatus de suspendido",
	"468": "Cliente no está en estatus activo",
	"469": "Cliente no está en estatus suspendido",
	"470": "El usuario no pertence al cliente",
	"471": "",
	"472": "",
	"473": "",
	"474": "",
	"475": "",
	"476": "",
	"477": "",
	"478": "",
	"479": "",
	"480": "",
	"481": "",
	"482": "",
	"483": "",
	"484": "",
	"485": "",
	"486": ""
}