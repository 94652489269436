import {Alert} from '@mui/material'

const ErrorAlert = ({
    error,
    onClose
}) => {


    if(!error) return

    let error_message = error?.message || error
    return ( 
        <Alert severity='error'  onClose={onClose}>
             {error_message}
        </Alert>
     );
}
 
export default ErrorAlert;