import { CONFIG_SETTINGS } from '../../config';
import { generic_server, private_server } from '../../utils/axios';
import { fileNameByUrl, fileTypeByUrl, isValidDocument } from '../../utils/files';


const useAnalytics = () => {   

    const actions = {
        //onGetAll: async(params) => {
        //    let result = {
        //        data:[], 
        //        count:0
        //    }
        //    const request = await private_server.get('/blog/all', {params:params})
        //    result.data = request.data.data.blogs
        //    result.count = request.data.data.count
        //    return result
        //},
        
        onGetGlobalPatientCount: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/globalpatientcount`, {params:params})
            result = request.data.data.global_patient_count
            return result
        },
        onGetGlobalAppointmentCount: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/globalappointmentcount`, {params:params})
            result = request.data.data.global_appointment_count
            return result
        },
        onGetUsersByObjective: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbyobjective`, {params:params})
            result = request.data.data.users_by_objective
            return result
        },
        onGetUsersByRiskGroup: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbyrisklevel`, {params:params})
            result = request.data.data.users_by_risk_level
            return result
        },
        onGetUsersByPathology: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbypathology`, {params:params})
            result = request.data.data?.users_by_pathology[0] ?? {}
            return result
        },
        onGetUsersByIMCDiagnostic: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbyimcdiagnostic`, {params:params})
            result = request.data.data.users_by_imc_diagnostic
            return result
        },
        onGetUsersByICCDiagnostic: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbyiccdiagnostic`, {params:params})
            result = request.data.data.users_by_icc_diagnostic
            return result
        },
        onGetUsersByDigestionStatus: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbydigestionstatus`, {params:params})
            result = request.data.data.users_by_digestion_status
            return result
        },
        onGetUsersByPhysicalConditionStatus: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbyphysicalcondition`, {params:params})
            result = request.data.data.users_by_physical_condition
            return result
        },
        onGetUsersBySleepQualityStatus: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbysleepquality`, {params:params})
            result = request.data.data.users_by_sleep_quality
            return result
        },
        onGetUsersByEnergyLevelStatus: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbyenergylevel`, {params:params})
            result = request.data.data.users_by_energy_level
            return result
        },
        onGetUsersByObjectiveExtended: async(params) => {
            let result = null
            const request = await private_server.get(`/analytic/usersbyobjectiveextended`, {params:params})
            result = request.data.data.users_by_objective_extended
            return result
        },
        onGetUsersExtended: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get(`/analytic/usersextended`, {params:params})
            result.data = request.data.data.users_extended
            result.count = request.data.data.count
            return result
        },
        

    }

    return {
        actions
    }
}
 
export default useAnalytics;