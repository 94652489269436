import React from 'react';
import Image from '../../images/Image';
import noimage from '../../../assets/icons/ecommerce/noimage-placeholder.png'
import { isValidDocument } from '../../../utils/files';
import { CONFIG_SETTINGS } from '../../../config';

const ProductImage = ({
    src
}) => {

    const isValid = isValidDocument(src)
    const imgSrc = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : noimage

    return ( 
        <Image src={imgSrc} alt={`producto`} ratio='1/1'/>
     );
}
 
export default ProductImage;