import { useEffect, useRef } from "react";
import { CONFIG_SETTINGS } from "../config";
import { isValidDocument } from "./files";
import jwt_decode from "jwt-decode";
import { catalogs } from "./catalogs";
import moment from "moment";
import { onGetDateWithTimezone } from "./formatTime";
import { GENDERS } from "./enums";

export const getRatio = (ratio = '1/1') => {
    return {
      '4/3': 'calc(100% / 4 * 3)',
      '3/4': 'calc(100% / 3 * 4)',
      '6/4': 'calc(100% / 6 * 4)',
      '4/6': 'calc(100% / 4 * 6)',
      '16/9': 'calc(100% / 16 * 9)',
      '9/16': 'calc(100% / 9 * 16)',
      '21/9': 'calc(100% / 21 * 9)',
      '9/21': 'calc(100% / 9 * 21)',
      '1/1': '100%',
    }[ratio];
}

export const onGetFullImagePath = (src) => {
  const valid = isValidDocument(src)
  if(!valid) return null
  return `${CONFIG_SETTINGS.S3_SERVER_URL}${src}`
}

export const onGetFullname = (user) => {
  let result = ''
  if(!user) return result
  if(user?.first_name)  result+=user?.first_name
  if(user?.fathers_last_name)  result= `${result} ${user?.fathers_last_name}`
  if(user?.mothers_last_name)  result= `${result} ${user?.mothers_last_name}`
  return result
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const string2Slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuuncescrzyuudtn------";

  for (var i=0, l=from.length ; i<l ; i++)
  {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace('.', '-') // replace a dot by a dash 
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by a dash
    .replace(/-+/g, '-') // collapse dashes
    .replace( /\//g, '' ); // collapse all forward-slashes

  return str;
}

export const onGetUserTypeFromToken = () => {
    const token = localStorage.getItem('accessToken');
    let user_type = 0
    if(token){
      const decodedToken = jwt_decode(token)
      user_type = decodedToken?.user_type_id
    }
    return user_type
}

export const s2ab = (s) =>{ 
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;    
}

export const onGetJSONData = async(datain) => {
  let result = null
  try {
    result = JSON.parse(datain)
  } catch (error) {
    console.log(error) 
  }
  return result
}

export const onGenerateRandomPassword = () => {
  var randomstring = Math.random().toString(36).slice(-8);
  return randomstring
}

export const onUpdateQuestionarySection = (origin, template) => {
  if(!origin) return origin
  let result = {...origin}
  try {
    result.module_name = template.module_name
    for(let i=0; i<result.questions.length; i++){
      const selected = template.questions[i]
      if(selected?.label){
        result.questions[i].label = selected.label
      }
      if(selected?.options){
        for(let j=0; j<selected.options.length; j++){
          if(selected.options[j].label){
            result.questions[i].options[j].label = selected.options[j].label
          }
          
        }
      }    
      
  
    }
  } catch (error) {
    
  }
 
  return result
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


export function generateArrayOfArrays(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
  }
  return result;
}

export const onCalculateCalories = (diet, meals) => {
  let _calories = 0
  let _equivalentscat = diet?.enable_smae_equivalents ? catalogs.equivalents_smae : catalogs.equivalents

  console.log("selected catalgo", _equivalentscat)

  _equivalentscat.forEach(item => {
      const _key = item?.key
      meals.forEach(el => {
          let _value = parseFloat(el[_key]) ??0
          if(isNaN(_value)) _value = 0
          _calories += (_value*item?.calories)
      })
  })

  return _calories
}
export function removeElementAt(array, position) {
  if (position < 0 || position >= array.length) {
    return array; // Return the original array if the position is out of bounds
  }
  
  return array.slice(0, position).concat(array.slice(position + 1));
}

export default function number2Digits(number, digits) {
  return number.toString().padStart(digits ?? 2, "0");
}

export const onGetAnalyticsRangeDates = (period) => {
    const month = number2Digits((period?.month ?? 0)+1)
    const year = period?.year
    const startdate = `${year}-${month}-01` 
    const enddate = moment(startdate).endOf('month').add(1,'d').format('YYYY-MM-DD')

    const isostartdate = moment(startdate)
    const isoenddate = moment(enddate)


    const startdate_previous = moment(startdate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
    const enddate_previous = moment(enddate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD')

    const isoprevstartdate = moment(startdate_previous)
    const isoprevenddate = moment(enddate_previous)

    return {
      current:{
        start_date:isostartdate.toISOString(),
        end_date:isoenddate.toISOString()
      },
      previous:{
        start_date:isoprevstartdate.toISOString(),
        end_date:isoprevenddate.toISOString()
      }
    }
}

export const onChangePeriod2UTCFormat = (period) => {
  let isostartdate = moment(period.start_date)
  let isoenddate = moment(period.end_date).endOf('d')

  return{
    start_date:isostartdate.toISOString(),
    end_date:isoenddate.toISOString()
  }

}


export const onGetEstimatedMetricsValues = (patient, metrics) => {
  function log10(x) {
      return Math.log(x) / Math.log(10);
  }

  console.log(patient)
  console.log(metrics)
  
  let bodyFatPercentage;
  let fatmassindex = 0;
  let leanmassindex = 0;
  let gender = patient?.gender_id;
  let waist = Number(metrics?.circumference_waist ?? 0.00);
  let hips = Number(metrics?.circumference_hips ?? 0.00);
  let neck = Number(metrics?.circumference_neck ?? 0.00);
  let height = Number(metrics?.height ?? 0.00);
  let weight = Number(metrics?.weight ?? 0.00);
  let fatmass = Number(metrics?.fat_mass ?? 0.00);
  let leanmass = Number(metrics?.lean_mass ?? 0.00);
  console.log(gender,waist,hips,neck, height,weight,fatmass, leanmass)

  if (gender === GENDERS.MALE) {
      bodyFatPercentage = 86.010 * log10(waist - neck) - 70.041 * log10(height) + 36.76;
  } else {
      if (hips === null) {
          // Hips measurement is required for females, handle accordingly
      }
      bodyFatPercentage = 163.205 * log10(waist + hips - neck) - 97.684 * log10(height) - 78.387;
  }

  // Ensure percentage is within realistic bounds
  bodyFatPercentage = Math.max(0, Math.min(100, bodyFatPercentage));

  const bodyFatMass = (bodyFatPercentage / 100) * weight;
  const leanBodyMass = weight - bodyFatMass;

  const height_m = height / 100;
  if (height > 0) {
      fatmassindex = fatmass / (height_m * height_m);
      leanmassindex = leanmass / (height_m * height_m);
  }

  return {
      bodyFatPercentage,
      bodyFatMass,
      leanBodyMass,
      fatMassIndex: fatmassindex,
      leanMassIndex: leanmassindex
  };
};
