import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number, format) {
  return numeral(number).format(format);
}

export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function onGetFormatNumber(number, pattern = '0.0') {
  const result = number ? numeral(number).format(pattern) : '';

  return result;
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export const cleanNumbers = (data) => {
  let result = {...data}
  Object.keys(data).forEach(item => {
      let _value = data[item]
      if(typeof(_value) === 'string'){
          _value = _value.replace('.000','')
          result[item] = _value
      }
  })
  return result
}
