import { Box, Stack, Typography } from '@mui/material';
import React from 'react'

const GlobalPyramidChart = ({
    total_users=0,
    initial_appointment_users=0,
    follow_up_appointment_users=0,
    scheduled_appointment_users=0,
    pending_users=0,
    disabled_users=0,
}) => {

    const _total = total_users === 0 ? 1 : total_users

    return ( 
        <Box>
            <Stack spacing={1}>
                <RowChart 
                    metric={total_users??0}
                    text={"Colaboradores inscritos"}
                />
                <RowChart 
                    metric={`${(parseFloat(initial_appointment_users/_total)*100).toFixed(2)}% (${initial_appointment_users??0} colaboradores)`}
                    text={"Consulta de primera vez"}
                    level={4}
                />
                <RowChart 
                    metric={`${(parseFloat(follow_up_appointment_users/_total)*100).toFixed(2)}% (${follow_up_appointment_users??0} colaboradores)`}
                    text={"En consulta de seguimiento"}
                    level={8}
                />
                <RowChart 
                    metric={`${(parseFloat(scheduled_appointment_users/_total)*100).toFixed(2)}% (${scheduled_appointment_users??0} colaboradores)`}
                    text={"Consulta agendada"}
                    level={12}
                />
                <RowChart 
                    metric={`${(parseFloat(pending_users/_total)*100).toFixed(2)}% (${pending_users??0} colaboradores)`}
                    text={"Pendientes de agendar cita"}
                    level={16}
                />
                <RowChart 
                    metric={`${(parseFloat(disabled_users/_total)*100).toFixed(2)}% (${disabled_users??0} colaboradores)`}
                    text={"Baja"}
                    level={18}
                />
            </Stack>
        </Box>
     );
}
 
export default GlobalPyramidChart;

const RowChart = ({
    metric,
    text,
    level
}) => {
    return(
        <Box sx={{
            px:level
        }}>
            <Box sx={{
                background: theme => theme.palette.primary.main,
                color:'white',
                py:1,
                borderRadius:1,
            }}>
                <Typography variant='h6' align='center' sx={{lineHeight:'1rem'}}>
                    {metric}
                </Typography>
                <Typography align='center'>
                    {text}
                </Typography>
                
            </Box>
        </Box>
    )
}